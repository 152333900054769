import { useParams } from 'react-router-dom';
import MerchantHome from '../home/merchant';
import { useStore } from '../../context/store.context';
import { useEffect } from 'react';

export default function Restaurant(){
  const { id } = useParams()
  const { getStore, setStoreSlug } = useStore()

  useEffect(() => {
    if (id) {
      setStoreSlug(id)
      getStore(id)
    }
  }, [id])

  return <MerchantHome view="list" slug={id} />
}