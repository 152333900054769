import BarkadaMarketHome from './layouts/barkadamarket.jsx';
import BarkadaDeliveryHome from './layouts/barkadadelivery.jsx';

export default function MarketplaceHome(){

  if (import.meta.env.VITE_APP_SLUG === "barkadamarket") {
    return <BarkadaMarketHome />
  } else if (import.meta.env.VITE_APP_SLUG === "barkadadelivery") {
    return <BarkadaDeliveryHome />
  }

  return null
}