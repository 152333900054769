import {
  Form, Panel, Steps, Content, Col, FlexboxGrid as View,
  DatePicker, InputPicker, ButtonGroup, Button, Divider,
  toaster, Message
} from 'rsuite';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCart, updateItem } from '../../actions/carts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt, faLocationDot, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { isBefore } from 'rsuite/esm/utils/dateUtils.js';
import { searchPlaces } from '../../actions/places.jsx';
import { FULLFILLMENT_TYPES } from '../../constants/data.jsx';
import { fetchCurrentUser } from '../../actions/authenticate.jsx';
import CartItem from '../../components/cart_item.jsx';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import Signup from './_signup.jsx';
import Login from './_login.jsx';
import { sendToMerchant } from '../../actions/carts.jsx';
import { useSettings } from '../../context/settings.context.jsx';

export default function Authenticate(){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  const { cart_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addressQuery, setAddressQuery] = useState('')
  const [placeId, setPlaceId] = useState(null)
  const [places, setPlaces] = useState([])
  const [loadingPlaces, setLoadingPlaces] = useState(false)
  const cart = useSelector(state => state.carts.byId)[cart_id]
  const currentUser = useSelector(state => state.user.data)
  const [deliveryAddressFieldShown, showDeliveryAddressField] = useState(true)
  const [activeKey, setActiveKey] = useState('Deliver')
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [outOfCoverage, setOutOfCoverage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatingQuantity, setUpdatingQuantity] = useState(false)
  const paymentEnabled = settings?.PAYMENT_ENABLED === "true"

  useEffect(() => {
    dispatch(fetchCurrentUser())
  }, [])

  useEffect(() => {
    setLoggedIn(!!currentUser?.id)
  }, [currentUser])


  useEffect(() => {
    console.log(isLoggedIn)
  }, [isLoggedIn])

  useEffect(() => {
    showDeliveryAddressField(!cart?.shipping_address)
    setActiveKey(cart?.fulfillment_type === "delivery" ? "Deliver" : "Pick-up")
  }, [cart])

  useEffect(() => {
    if (addressQuery.length > 3) {
      const timer = setTimeout(async () => {
        setLoadingPlaces(true)
        searchPlaces(settings.COUNTRIES, addressQuery, (results) => {
          if (results) {
            setPlaces(results.map(place => place.placePrediction))
          }
          setLoadingPlaces(false)
        })
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [addressQuery])

  const updateQuantity = (item, quantity) => {
    setUpdatingQuantity(item)
    dispatch(updateItem(cart.id, {
      variant_id: item.variant_id,
      quantity,
      store_id: cart.store.id
    }, () => {
      setUpdatingQuantity(false)
    }))
  }

  const onValidateCheckout = () => {
    if (!currentUser?.id) {
      toaster.push(<Message showIcon type="error">
        Please provide your contact information
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "delivery" && !cart.shipping_address?.place_id) {
      toaster.push(<Message showIcon type="error">
        Please select a delivery address
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "delivery" && !cart.delivery_date) {
      toaster.push(<Message showIcon type="error">
        Please select a delivery date
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "delivery" && 
      isBefore(Date.parse(cart.delivery_date), new Date().setHours(0, 0, 0, 0))
    ) {
      toaster.push(<Message showIcon type="error">
        Choose a date for delivery in the future.
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "pickup" && !cart.pickup_date) {
      toaster.push(<Message showIcon type="error">
        Please select a pick-up date
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "pickup" && 
      isBefore(Date.parse(cart.pickup_date), new Date().setHours(0, 0, 0, 0))
    ) {
      toaster.push(<Message showIcon type="error">
        Choose a date for pick-up in the future.
      </Message>)
      return false
    }

    return true
  }

  const onProceedToPayment = () => {
    if (onValidateCheckout())
      navigate(`/payments/${cart?.id}`)
  }

  const onSendOrder = () => {
    if (onValidateCheckout()) {
      setLoading(true)
      dispatch(sendToMerchant(cart.id, {}, (response, error) => {
        if (response)
          navigate(`/payments/${cart?.id}/success`)
        setLoading(false)
      }))
    }
  }
  const onUpdate = (params, callback = () => {}) => {
    if (cart) dispatch(updateCart(cart.id, params, callback))
  }

  if (!cart) return null
  return (
    <Content >
      <View justify='center'>
        <View.Item as={Col} colspan={24} style={{maxWidth: 1120, paddingTop: 30}}>
          <Content >
            <div style={{marginBottom: 40, paddingTop: 30}}>
              <Steps current={1} small>
                <Steps.Item title={<Link to="/carts">Cart</Link>} />
                <Steps.Item title="Customer Information" />
                <Steps.Item title="Payment" status="wait"/>
              </Steps>
            </div>
            {false && <Message type='warning' style={{ marginBottom: 20 }}>
              <strong style={{ marginBottom: 5, display: "block" }}>Delivery Cutoff!</strong>
              <p>To ensure your order is processed for the next delivery cycle, please be aware of the cutoff time. Orders placed after this time will be scheduled for the following delivery date.</p>
              <hr></hr>
              Thank you for choosing us for your delivery needs!
            </Message>}
            <View>
              <View.Item as={Col} colspan={16} lg={16} md={16} sm={24} xs={24}>
                <Panel bordered>
                  <h3>Customer Information</h3>
                  {!isLoggedIn && <p>
                    Please provide your contact information below. This information will be used to contact you about your order.
                  </p>}
                  <Divider />
                  <View>
                    {isLoggedIn && <View.Item as={Col} colspan={24}>
                      <h6>Welcome back {currentUser?.first_name}</h6>
                      <p>
                        <span>Please confirm your following information below. If you need to update your information, please click on the link below. </span>
                      </p>
                      <br/>
                      <Form>
                        <Form.Group>
                          <Form.ControlLabel>First Name:</Form.ControlLabel>
                          <Form.Control
                            name="first_name"
                            plaintext
                            value={currentUser?.first_name}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.ControlLabel>Last Name:</Form.ControlLabel>
                          <Form.Control
                            name="last_name"
                            plaintext
                            value={currentUser?.last_name}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.ControlLabel>Email:</Form.ControlLabel>
                          <Form.Control
                            name="email"
                            plaintext
                            value={currentUser?.email}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.ControlLabel>Phone:</Form.ControlLabel>
                          <Form.Control
                            name="phone"
                            plaintext
                            value={currentUser?.phone}
                          />
                        </Form.Group>
                      </Form>
                      <br/>
                      {!loading && <Link onClick={() => setLoggedIn(false)}>This is not my account.</Link>}
                    </View.Item>}

                    {!isLoggedIn && <View.Item as={Col} colspan={12} lg={12} md={12} sm={24} xs={24}>
                      <Signup setLoggedIn={setLoggedIn}/>
                    </View.Item>}

                    {!isLoggedIn && <View.Item as={Col} colspan={12} lg={12} md={12} sm={24} xs={24}>
                      <Login setLoggedIn={setLoggedIn}/>
                    </View.Item>}
                  </View>
                </Panel>
              </View.Item>
              <View.Item className='sm-cart-container' as={Col} colspan={8} lg={8} md={8} sm={24} xs={24}>
                <Panel bodyFill>
                  {cart && <Panel className='sm-cart-wrapper' bordered bodyFill>
                  <div className="sm-cart-header">
                    <h6>
                      <FontAwesomeIcon icon={faLocationDot} />
                      Address
                    </h6>
                  </div>
                  <div className="sm-cart-list">
                    <div className='sm-cart-item'>
                      <ButtonGroup justified style={{marginBottom: 10}}>
                        {['Deliver', 'Pick-up'].map(key => (
                          <Button disabled={loading || cart.state !== "cart"} key={key} active={key === activeKey} onClick={() => {
                            onUpdate({fulfillment_type: FULLFILLMENT_TYPES[key]})
                            setActiveKey(key)
                          }}>
                            {key}
                          </Button>
                        ))}
                      </ButtonGroup>
                      {cart?.fulfillment_type === "delivery" &&  <DatePicker
                        shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                        format="MM/dd/yyyy"
                        showMeridian
                        className='m-b-10'
                        placeholder="Select Delivery Date"
                        block
                        disabled={loading || cart.state !== "cart"}
                        defaultValue={cart?.delivery_date ? new Date(cart?.delivery_date) : null}
                        onChange={(date) => onUpdate({delivery_date: date})}
                      />}
                      {cart?.fulfillment_type === "delivery" &&  <Form fluid>
                        <Form.Group controlId="address">
                          {!deliveryAddressFieldShown && <div>
                            <p>
                              {cart?.shipping_address?.address_text}
                              {cart.state === "cart" && <Divider vertical />}
                              {cart.state === "cart" && <a href="#" onClick={(e) => {
                                e.preventDefault()
                                setOutOfCoverage(false)
                                showDeliveryAddressField(true)
                              }
                              }>Change</a>}
                            </p>
                          </div>}
                          {outOfCoverage && <Message
                            type="warning"
                            className='sm-cart-prompt-msg m-b-10'
                            style={{marginTop: 10}}
                          >
                            Sorry, this merchant doesn't deliver here.
                          </Message>}
                          {deliveryAddressFieldShown && <Form.Control
                            accepter={InputPicker}
                            autoFocus
                            cleanable
                            disabled={loading || cart.state !== "cart"}
                            name="address1"
                            data={places.map(place => ({label: place.text.text, value: place.placeId}))}
                            placeholder="ex. 123 Main St."
                            block
                            onClean={() => showDeliveryAddressField(false)}
                            renderMenu={menu => {
                              if (loadingPlaces) {
                                return (
                                  <p style={{ padding: 10, textAlign: 'center' }}>
                                    <SpinnerIcon spin /> Loading...
                                  </p>
                                );
                              }
                              return menu;
                            }}
                            onSelect={(place_id, {label}) => {
                              setOutOfCoverage(false)
                              setPlaceId(place_id)
                              onUpdate({place: { id: place_id, text: label }}, (response, error) => {
                                if (error?.response?.status === 422) {
                                  setOutOfCoverage(true)
                                }
                                if (response) {
                                  setOutOfCoverage(false)
                                }
                              })
                            }}
                            menuClassName='mp-auto-complete'
                            valueKey="value"
                            labelKey="label"
                            onSearch={setAddressQuery}
                          />}
                          {!cart?.shipping_address?.place_id && 
                            <Form.HelpText>Please enter a delivery location with street number</Form.HelpText>
                          }
                        </Form.Group>
                      </Form>}
                      {cart?.fulfillment_type === "pickup" && <div>
                        <DatePicker
                          disabled={loading || cart.state !== "cart"}
                          shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                          className='m-b-5'
                          format="MM/dd/yyyy"
                          placeholder="Select Pickup Date"
                          block
                          showMeridian
                          defaultValue={cart?.pickup_date ? new Date(cart?.pickup_date) : null}
                          onChange={(date) => onUpdate({pickup_date: date})}
                        />
                        <Form.HelpText>Please pick up your order at the store in the address below:</Form.HelpText>
                        <p style={{paddingTop: 10, paddingBottom: 10, fontWeight: 'bold'}}>
                        {cart.store?.location}
                        </p>
                      </div>}
                    </div>
                  </div>
                </Panel>}
                <Panel className='sm-cart-wrapper' bordered bodyFill>
                  <div className="sm-cart-header">
                    <h6>
                      <FontAwesomeIcon icon={faBagShopping} />
                      Your Shopping Cart
                    </h6>
                  </div>
                  <div className="sm-cart-list">
                    {!cart && <div className='sm-cart-item'>
                      <p>There are no items in your cart</p>
                    </div>}
                    {cart?.line_items?.map((item, index) => (
                      <CartItem
                        disabled={loading || cart.state !== "cart"}
                        item={item}
                        key={index}
                        compact
                        updating={updatingQuantity}
                        onUpdateQuantity={updateQuantity}
                      />
                    ))}
                  </div>
                </Panel>
                {cart && <Panel className='sm-cart-wrapper' bordered bodyFill>
                  <div className="sm-cart-header">
                    <h6>
                      <FontAwesomeIcon icon={faReceipt} />
                      Summary
                    </h6>
                  </div>
                  <div className="sm-cart-list sm-cart-summary">
                    <View className='sm-cart-item'>
                      <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                        <p>Subtotal</p>
                      </View.Item>
                      <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                        <p className='align-right'>
                          {!cart ? 0 : `${parseFloat(cart?.item_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                        </p>
                      </View.Item>
                    </View>
                    {cart?.fulfillment_type === "delivery" && <View className='sm-cart-item'>
                      <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                        <p>Delivery</p>
                      </View.Item>
                      <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                        <p className='align-right'>
                          {!cart ? 0 : `${parseFloat(cart?.display_shipment_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                        </p>
                      </View.Item>
                    </View>}
                    {settings.SERVICE_FEE_EXCLUSIVE === "true" && <View className='sm-cart-item'>
                      <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                        <p>Convenience Fee</p>
                      </View.Item>
                      <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                        <p className='align-right'>
                          {!cart ? 0 : `${parseFloat(cart?.display_service_fee).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                        </p>
                      </View.Item>
                    </View>}
                    <View className='sm-cart-item'>
                      <View.Item as={Col} colspan={24} className='m-b-5'><Divider className='m-t-5 m-b-5'/></View.Item>
                      <View.Item as={Col} colspan={24} lg={14} md={24} sm={24} xs={24}>
                        <h6>TOTAL AMOUNT</h6>
                      </View.Item>
                      <View.Item as={Col} colspan={24} lg={10} md={24} sm={24} xs={24}>
                        <h6 className='align-right'>
                        {!cart ? 0 : `${parseFloat(cart?.display_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                      </h6>
                      </View.Item>
                    </View>
                    <View className='sm-cart-item'>
                      <View.Item as={Col} colspan={24} >
                        {paymentEnabled && <Button appearance="primary" block onClick={() => {
                          onProceedToPayment()
                        }}>Proceed to Payment</Button>}
                        {!paymentEnabled && <Button loading={loading} appearance="primary" block onClick={() => {
                          onSendOrder()
                        }}>Send Order</Button>}
                      </View.Item>
                    </View>
                  </div>
                </Panel>}
              </Panel>
              </View.Item>
            </View>
          </Content>
        </View.Item>
      </View>
    </Content>
  );
}