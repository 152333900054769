import { Divider, Panel, Steps, Content, Col, FlexboxGrid as View, Button } from 'rsuite';
import { useEffect, useState } from 'react';
import { checkout, fetchPaymentMethods } from "../../actions/payments";
import { useStore } from '../../context/store.context';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createPaymentIntent } from '../../actions/carts';
import { fetchCurrentUser } from '../../actions/authenticate';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './_checkoutForm';
import { useSettings } from '../../context/settings.context';

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY);

export default function Payment(){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  const { id } = useParams()
  const { store } = useStore()
  const dispatch =  useDispatch()
  const [checkingOut, setCheckingOut] = useState(false)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [redirecting, setRedirecting] = useState(false)
  const [fee, setFee] = useState(0)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [gateway, setGateway] = useState(null)
  const [stripeOptions, setStripeOptions] = useState(null)
  const cart = useSelector(state => state.carts.byId)[id]
  const user = useSelector(state => state.user.data)
  
  useEffect(() => {
    if (!user?.id) dispatch(fetchCurrentUser())
    createPaymentIntent({cart: {id}, user: {id: user.id}}, (response, error) => {
      const { clientSecret } = response
      if (clientSecret)
        setStripeOptions({ clientSecret })
    })
  }, [])
  
  useEffect(() => {
    setLoading(true)
    const filters = (item) => {
      if (settings.BRANKAS_ENABLED === "true") {
        return item.attributes.is_brankas
      }
      return true
    }
    fetchPaymentMethods((response, error) => {
      if (response)
        setPaymentMethods(response.data.payment_methods.filter(filters).map(item => item.attributes))
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (cart) {
      if (paymentMethod && paymentMethod?.is_brankas) {
        const fee = Math.ceil(cart.total / 50000) * 15
        setFee(fee)
        setTotal(parseFloat(cart.total) + fee)
      } else {
        setFee(parseFloat(cart.display_total_fee))
        setTotal(parseFloat(cart.display_total))
      }
    }
  }, [cart, paymentMethod])

  const onPay = async (paymentMethod) => {
    const type = paymentMethod.code
    setRedirecting(true)
    checkout({
      data: {
        amount: parseInt(total),
        currency: cart.currency.toUpperCase(),
        type,
        cart_id: cart.id,
        gateway
      }
    }, async (response, error) => {
      if (gateway === "brankas") {
        if (response) {
          const brankas_redirect_url = response.data.result.short_redirect_uri
          window.location.href = brankas_redirect_url
        }
        if (error?.response) {
          console.log(error?.response)
        }
      }
    })
  }

  const paymentMethodFilters = (item) => {
    return (!loading || loading === item.code)
  }

  if (!cart) return null
  return (
    <Content >
      <View justify='center'>
        <View.Item as={Col} colspan={24} lg={15} md={22} sm={24} style={{maxWidth: 1120, paddingTop: 30}}>
          <Content >
            <div style={{marginBottom: 50}}>
              <Steps current={2} small>
                <Steps.Item title="Checkout" />
                <Steps.Item title="Customer Information" />
                <Steps.Item title="Payment" />
              </Steps>
            </div>
            <View justify="center">
              <View.Item as={Col} colspan={12} md={12} sm={24} xs={24}>
                <Panel bordered style={import.meta.env.VITE_APP_THEME === "dark" ? styles.dark : styles.light}>
                  <View justify="space-between">
                    <View.Item>
                      <p>Subtotal</p>
                    </View.Item>
                    <View.Item>
                      {cart && <p>{`${parseFloat(cart?.item_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</p>}
                    </View.Item>
                  </View>
                  <View justify="space-between">
                    <View.Item>
                      <p>Delivery Fee</p>
                    </View.Item>
                    <View.Item>
                      {cart && `${parseFloat(cart?.display_shipment_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                    </View.Item>
                  </View>
                  {settings.SERVICE_FEE_EXCLUSIVE === "true" && <View justify="space-between">
                    <View.Item>
                      <p>Convenience Fee</p>
                    </View.Item>
                    {cart && <View.Item>
                      {parseFloat(paymentMethod?.is_brankas ? fee : cart.display_service_fee).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}
                    </View.Item>}
                  </View>}
                  <Divider/>
                  <View justify="space-between">
                    <View.Item>
                      <h6>Total</h6>
                    </View.Item>
                    <View.Item>
                      {cart && <h6>{`${parseFloat(total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</h6>}
                    </View.Item>
                  </View>
                </Panel>
                {settings.STRIPE_ENABLED === "true" && <Panel style={{background: '#fff', display: (!paymentMethod ? '' : 'none')}}>
                  {!!stripeOptions && <Elements stripe={stripePromise} options={stripeOptions}>
                    <CheckoutForm cart_id={id}/>
                  </Elements>}
                </Panel>}
                {cart?.currency.toLowerCase() === "php" && <div>
                  <Divider/>
                  {!paymentMethod && <View>
                    {paymentMethods?.slice(0, Math.ceil(paymentMethods.length / 2))
                      .filter(paymentMethodFilters)
                      .map((item, index) => {
                        return(
                          <View.Item key={index} colspan={12} style={{padding: 5}}>
                            <Button block disabled={!!loading} style={{height: 60, padding: 10, background: 'white', opacity: (!!loading) ? 0.6 : 1}} onClick={() => {
                              setGateway('brankas')
                              setPaymentMethod(item)
                            }}>
                              {loading === item.code ? 
                                <strong>{` `}</strong>
                              :
                                <img
                                  alt=""
                                  width={175}
                                  height={40}
                                  resizeMode="contain"
                                  src={item.logo_url}
                                  style={{width: "100%", height: "100%"}}
                                />
                              }
                            </Button>
                          </View.Item>
                        )
                      })
                    }
                  </View>}
                  {!paymentMethod && <View>
                    {paymentMethods?.slice(Math.ceil(paymentMethods.length / 2))
                      .filter(paymentMethodFilters)
                      .map((item, index) => {
                        return(
                          <View.Item key={index} colspan={12} style={{padding: 5}}>
                            <Button block disabled={!!loading} style={{height: 60, padding: 10, background: 'white', opacity: (!!loading) ? 0.6 : 1}} onClick={() => {
                              setGateway('brankas')
                              setPaymentMethod(item)
                            }}>
                              {loading === item.code ? 
                                <strong>{` `}</strong>
                              :
                                <img
                                  alt=""
                                  width={175}
                                  height={40}
                                  resizeMode="contain"
                                  src={item.logo_url}
                                  style={{width: "100%", height: "100%"}}
                                />
                              }
                            </Button>
                          </View.Item>
                        )
                      })
                    }
                  </View>}
                  {!!paymentMethod && <View style={{marginBottom: 20}} justify="space-between">
                    <View.Item colspan={12}>
                      <strong>You will be paying with:</strong>
                      <Button size="sm" appearance="link" onClick={() => setPaymentMethod(null)}>
                        <small>Change Payment Method</small>
                      </Button>
                    </View.Item>
                    <View.Item colspan={12}>
                      <div style={{background: 'white', padding: 4, height: 40, textAlign: 'center', overflow: 'hidden'}}>
                        <img
                          width={40}
                          height={40}
                          alt={paymentMethod.code}
                          resizeMode="contain"
                          src={paymentMethods.filter(item => item.code === paymentMethod.code)[0].logo_url}
                          style={{width: "100%", height: "100%"}}
                        />
                      </div>
                    </View.Item>
                  </View>}
                </div>}
                {redirecting && <p style={{marginBottom: 20}}><small style={{textAlign: 'center'}}>
                  You will be redirected to <strong>{paymentMethod?.is_brankas ? "BRANKAS" : "a payment gateway"}</strong> to complete your purchase. Do not close this window until you have completed your payment.
                </small></p>}

                {!!paymentMethod && <View>
                  <Button loading={redirecting} block appearance="ghost" onClick={() => onPay(paymentMethod)}>Confirm Payment</Button>
                </View>}
              </View.Item>
            </View>
          </Content>
        </View.Item>
      </View>
    </Content>
  );
}

const styles = {
  light: {
    background: 'white',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    marginBottom: 10
  },
  dark: {
    background: "#2b2b2b",
    minWidth: 200,
    width: '100%',
    padding: 20,
    marginBottom: 10
  }
}