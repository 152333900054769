import { FlexboxGrid as View, Col, Panel,} from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSettings } from '../context/settings.context';

export default function StoreCard({store}){
  const { settings } = useSettings();
  let shaded;
  let bordered;
  if (settings.APP_THEME === 'dark') {
    shaded = !store?.featured_at
    bordered = !!store?.featured_at
  } else {
    shaded = !!store?.featured_at
    bordered = !store?.featured_at
  }
  return(
    <View.Item as={Col} colspan={24} lg={6} md={6} sm={24} xs={12} className='tile-item p-b-20'>
      <Panel shaded={shaded} bordered={bordered} bodyFill style={{position: 'relative'}}>
        <Link className='tile-item-img-md' to={`/restaurants/${store.slug}`}>
          <img width={195} height={195} src={store.logo}  />
          {!!store.featured_at && <FontAwesomeIcon icon={faStar} style={{position: 'absolute', top: 6, right: 6, color: "var(--tablenos-primary-color)"}}/>}
          {!store.logo && 
            <span className='empty-img'><FontAwesomeIcon icon={faImage}/></span>
          }
        </Link>
        <div className='tile-item-title'>
          <Link className='stack-media-title ellipsis-1 fw-700' to={`/restaurants/${store.slug}`}>
            {store.name}
          </Link>
          {/* <p>
            <small>
              Pizza, Pasta, Wings
            </small>
          </p> */}
        </div>
      </Panel>
    </View.Item>
  )
}