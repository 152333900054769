import axios from 'axios'
/**
 * Api http
 *
 * @param opts
 * @returns {axios}
 */
const gAxios = (opts = {}) => {
  
  const rootURL = import.meta.env.VITE_API_BASE_URL

  const {
    auth = true,
    version = 'api/storefront',
    multipart = false,
    xhrFields = {},
  } = opts

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }

  if (multipart) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  const ax = axios.create({
    baseURL: `${rootURL}/${version}`,
    timeout: 60000,
    headers,
    xhrFields,
    withCredentials: false
  })

  ax.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // if (response.headers['x-rolling-token'])
    if (response.headers['x-rolling-token']) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.token = response.headers['x-rolling-token']
      localStorage.setItem('user', JSON.stringify(user));
    }
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

  ax.interceptors.request.use(async function(options) {
    const token = JSON.parse(localStorage.getItem('user'))?.access_token
    if (!!auth && auth !== 'auto' && !token) {
      return Promise.reject(new TokenError())
    }

    if (!!auth && token) {
      options.headers['Authorization'] = `Bearer ${token}`
    } else {
      // unset cancel token for noauth requests
      options.cancelToken = null
    }

    return options
  }, handleRequestError)

  return ax
}

export function handleRequestError(error) {
  console.warning('Request error: ', error)
  if (axios.isCancel(error)) {
    return
  }
  return Promise.reject(error)
}

export class TokenError extends Error {
  constructor() {
    let msg = 'Auth token is absent'

    super(msg)

    this.request = {
      data: msg,
      status: 401,
    }
  }
}

export default gAxios