import { Panel, Content, Col, FlexboxGrid as View } from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons/faBasketShopping';
import { useSelector } from 'react-redux';
import '../../assets/cart.scss';
import Cart from './show';

export default function Carts(){
  const carts = useSelector(state => state.carts.byId);

  const cartItemsCount = Object.values(carts).reduce((acc, cart) => {
    return acc + cart.line_items?.length;
  }, 0);

  const sortByCreatedAt = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  }

  return (
    <Content>
      <View justify='center' className='cart-orders'>
        <View.Item
          as={Col}
          colspan={24}
          lg={17}
          md={22}
          sm={24}
          style={{
            maxWidth: 1120
          }}
        >
          {!!cartItemsCount ?
            <View.Item>
              <h6 className='m-b-10'>Review your order</h6>
            </View.Item>
          :
            <View.Item>
              <Panel className='cart-is-empty' bordered>
                <FontAwesomeIcon icon={faBasketShopping}/>
                <h4 >Your cart is empty</h4>
                <p>Looks like you have not added anything to your cart. Explore our store...</p>
              </Panel>
            </View.Item>
          }
          {Object.values(carts).sort(sortByCreatedAt).map((cart, index) => <Cart key={index} cart={cart}/>)}
        </View.Item>
      </View>
    </Content>
  );
}