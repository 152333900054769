import { useStore } from '../context/store.context';
import {FlexboxGrid as View, Col} from 'rsuite';

export default function StoreCover({src, alt}){
  const { store } = useStore()

  if (!store?.cover) return null
  return (
    <div className='cover-container'>
      <div className='cover-image-wrapper'>
        <img className='img-cover' width={1120} height={500} src={store?.cover} alt={alt}/>
      </div>
      <View justify='center'>
        <View.Item as={Col} colspan={24} lg={17} md={22} sm={24} style={{maxWidth: 1120}}>
          <img className='cover-logo' width={1120} height={500} src={store?.logo} alt={alt} />
        </View.Item>
      </View>
    </div>
  );
}