import { createSlice } from "@reduxjs/toolkit";

const prototypesSlice = createSlice({
  name: "prototypes",
  initialState: {
    byId: {},
    keys: [],
    included: [],
    meta: {},
    links: {},
    fetching: true,
  },
  reducers: {
    setValues: (state, action) => {
      return({
        ...state,
        byId: action.payload,
        keys: [
          ...new Set(Object.keys(action.payload))
        ],
        links: action.payload.links,
        fetching: false,
      })
    },
    setValue: (state, action) => {
      return({
        ...state,
        byId: {
          ...state.byId,
          ...action.payload
        },
        keys: Object.keys({
          ...state.byId,
          ...action.payload
        }),
        links: action.payload.links,
        fetching: false,
      })
    },
    setMeta: (state, action) => {
      return({
        ...state,
        meta: action.payload,
      })
    }
  },
});

export const { setValues, setValue, setMeta } = prototypesSlice.actions;

export default prototypesSlice.reducer;