import { Header, Navbar, Nav, Badge } from 'rsuite';
import { useStore } from '../context/store.context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../context/cart.context';
import { useNavigate } from 'react-router-dom';

export default function TopNav({closable, onClose = () => {}}) {
  const navigate = useNavigate()
  const { store } = useStore()
  const { cartItemsCount } = useCart()

  const isDomainContains = (term) => {
    if (typeof window !== 'undefined') {
      const domainName = window.location.hostname;
      return domainName.toLowerCase().includes(term);
    } else {
      return false;
    }
  }

  const Logo = () => {
    if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace') {
      return <img src={`/${import.meta.env.VITE_APP_SLUG}.png`} alt={import.meta.env.VITE_APP_NAME}/>
    } else if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
      if (isDomainContains('unisol')) {
        return <img src="/unisol.png" alt="Unisol"/>
      } else {
        return <img width={30} height={30}  src={store?.logo} alt="logo" style={{ height: 30 }} />
      }
    }
  }

  return(
    <Header>
      <Navbar style={{ backgroundColor: '#0A0A0A', paddingRight: 0 }} appearance='inverse'>
        <Navbar.Brand href='/' style={{ color: '#fff' }} onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}>
            <Logo />
        </Navbar.Brand>
        <Nav pullRight>
          <Nav.Item className='modal-product-checkout' onSelect={() => {
            navigate('/carts')
          }} icon={
            <FontAwesomeIcon icon={faShoppingCart} />
          }>
            {cartItemsCount > 0 && <Badge content={cartItemsCount} style={{position: 'absolute', top: 11, right: 18}}/>}
          </Nav.Item>
          {closable && <Nav.Item className='modal-product-close' onSelect={() => onClose()} icon={
            <FontAwesomeIcon icon={faClose} />
          }>
            Close
          </Nav.Item>}
        </Nav>
      </Navbar>
    </Header>
  );
}
