import api from './common/api'
import { setValue, setValues } from '../slices/carts'

export const fetchCarts = 
  (ids, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .get(`/carts?ids=${ids}`)
      .then((response, error) => {
        const carts = {}
        
        response?.data?.carts?.data?.forEach(item => {
          carts[item.attributes.id] = item.attributes
        })
        dispatch(setValues(carts))
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const fetchCart =
  (id, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .get(`/carts/${id}`)
      .then((response, error) => {
        const cart = {};
        cart[response.data.cart.data.attributes.id] =
          response.data.cart.data.attributes;
        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const updateCart =
  (id, params, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .put(`/carts/${id}`, params)
      .then((response, error) => {
        const cart = {};
        cart[response.data.cart.data.attributes.id] =
          response.data.cart.data.attributes;
        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const updateCartStatusToPayment =
  (id, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .put(`/carts/${id}/update_order_payment_status`, {})
      .then((response, error) => {
        const cart = {};
        cart[response.data.cart.data.attributes.id] =
          response.data.cart.data.attributes;
        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const createCart =
  (params, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .post(`/carts`, params)
      .then((response, error) => {
        const cart = {};
        cart[response.data.cart.data.attributes.id] =
          response.data.cart.data.attributes;
        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const addToCart =
  (id, params, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .post(`/carts/${id}/add_item`, params)
      .then((response, error) => {
        const cart = {};
        const data = response.data.cart.data.attributes
        cart[data.id] = data;
        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const updateItem =
  (id, params, cb = null) =>
  async (dispatch) => {
    return api({auth: false})
      .put(`/carts/${id}/update_item`, params)
      .then((response, error) => {
        const cart = {};
        cart[response.data.cart.data.attributes.id] =
          response.data.cart.data.attributes;

        dispatch(setValue(cart));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const createCheckoutSession =
  async (params, cb = null) => {
    const url = `/payments/checkout`;
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(params)
    });

    
    try {
      const json = await response.json();

      if (response.status === 200) {
        if (cb) cb(json, null);
      } else {
        if (cb) cb(null, json?.data);
      }
    } catch(error) {
      if (cb) cb(null, error);
    };
  };

export const sendToMerchant =
  (id, params, cb = null) =>
  async (dispatch) => {
    return api()
      .put(`/carts/${id}/send_to_merchant`, params)
      .then((response, error) => {
        const order = {};
        order[response.data.order.data.attributes.id] =
          response.data.order.data.attributes;
        dispatch(setValue(order));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const createPaymentIntent =
  async (params, cb = null) => {
    const url = `/payments/payment_intent`;
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(params)
    });
    
    try {
      const json = await response.json();

      if (response.status === 200) {
        if (cb) cb(json, null);
      } else {
        if (cb) cb(null, json?.data);
      }
    } catch(error) {
      if (cb) cb(null, error);
    };
  };
