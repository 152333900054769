import { Divider, Button, Panel, Rate, Content, Col, FlexboxGrid as View } from 'rsuite';
import { useParams } from "react-router-dom";
import Image from '../../assets/canceled.svg';
import { useNavigate } from "react-router-dom";

export default function Failed(){
  const navigate = useNavigate()
  const { cart_id } = useParams()
  
  return (
    <Content>
      <View justify="center">
        <View.Item as={Col} colspan={8} md={8} sm={8} xs={24}>
          <div style={{textAlign: 'center', marginBottom: 20}}>
            <h3 style={{marginBottom: 10}}>We're sorry to see you cancel</h3>
            <p>Thinking it over? Let’s revisit your choices and see if they’re the perfect fit for you!</p>
            <img src={Image} alt="Success" style={{width: 300}}/>
          </div>
          <p style={{textAlign: 'center'}}>
            <div style={{marginBottom: 20}}>
              <small style={{marginBottom: 20}}>
                Your selections are waiting just for you! Ready to make it official? Review your cart and secure your items at checkout now.
              </small>
            </div>
            <Button appearance="ghost" onClick={() => navigate('/cart')}>Go to Cart</Button>
          </p>
        </View.Item>
      </View>
    </Content>
  );
}