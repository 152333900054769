import api from './common/api'
import { setValue, setValues, setMeta } from '../slices/stores'

export const fetchStores = (params, cb = null) => async dispatch => {
  const query = new URLSearchParams(params).toString();
  return api({auth: false})
  .get(`/stores?${query}`).then(response => {
    const stores = {}

    response?.data?.stores?.data?.forEach(item => {
      stores[item.id] = item.attributes
    })
    dispatch(setValues(stores))
    dispatch(setMeta(response?.data?.meta))
  }).catch(error => {
    console.error(error);
  }).finally(() => {
    console.log("done")
    if (cb) cb()
  });
}


export const fetchStore =
  (id, params, cb = null) =>
  async (dispatch) => {
    const query = new URLSearchParams(params).toString();
    return api({auth: false})
      .get(`/stores/${id}?${query}`)
      .then((response, error) => {
        const store = {};
        store[response.data.store.data.id] =
          response.data.store.data.attributes;
        dispatch(setValue(store));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };
