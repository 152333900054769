import api from './common/api'
import axios from "axios";

export const searchPlaces = async (countries, query, callback) => {
  return axios.post('https://places.googleapis.com/v1/places:autocomplete', {
    input: query,
    includedRegionCodes: countries.split(",")
  }, {
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': import.meta.env.VITE_APP_REACT_APP_GOOGLE
    }
  }).then((response) => {
    if (response.status === 200) {
      callback(response.data.suggestions)
    }
  }).catch((error) => {
    console.error(error)
  })
}

export const validatePlace = async (id, params, cb = null) => {
    const query = new URLSearchParams(params).toString();
    return api({auth: false})
      .get(`/products/${id}?${query}`)
      .then((response, error) => {
        const product = {};
        product[response.data.product.data.attributes.id] =
          response.data.product.data.attributes;
        dispatch(setValue(product));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };