import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button } from 'rsuite';
import { useState } from 'react';

const CheckoutForm = ({cart_id}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkingOut, setCheckingOut] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setCheckingOut(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: import.meta.env.VITE_APP_URL + `/payments/${cart_id}/success`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setCheckingOut(false);
    } else {
      setCheckingOut(false);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br/>
      <Button onClick={handleSubmit} disabled={!stripe} appearance="primary" block loading={checkingOut}>
        Pay with Debit/Credit Card
      </Button>
    </form>
  )
};

export default CheckoutForm;