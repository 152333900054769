import { Panel,FlexboxGrid as View, Stack, Button, Grid, Row, Col, ButtonGroup } from 'rsuite';
import { useSettings } from '../context/settings.context';

export default function CartItem({item, disabled = false, updating, onUpdateQuantity, compact = false, editable = true}){
  const { settings } = useSettings()
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  if (compact) return(
    <View className='sm-cart-item' align='middle'>
      <View.Item as={Col} colspan={24} lg={12} md={12} sm={12} xs={12}>
        <p>
          <strong>{item.variant.name}</strong>
        </p>
        <small>
          {parseFloat(item.price).toLocaleString(undefined, {style:"currency", currency: item.currency, minimumFractionDigits: 0, maximumFractionDigits})}
        </small>
      </View.Item>
      <View.Item as={Col} colspan={24} lg={12} md={12} sm={12} xs={12} style={{minWidth: 100, textAlign: 'right'}}>
        {editable ? <ButtonGroup className='qty-control qty-control-sm'>
          <Button size="xs" disabled={disabled || updating?.id === item.id} appearance="default" onClick={() => onUpdateQuantity(item, item.quantity - 1)}>-</Button>
          <Button className='qty-control-count' size="xs" loading={updating?.id === item.id} disabled appearance="subtle">{item.quantity}</Button>
          <Button size="xs" disabled={disabled || updating?.id === item.id} appearance="default" onClick={() => onUpdateQuantity(item, item.quantity + 1)}>+</Button>
        </ButtonGroup> : <small>{item.quantity}</small>}
      </View.Item>
    </View>
  )
  return(
    <Panel bodyFill className="cart-item">
      <View className='fluid'>
        <View.Item as={Col} colspan={24} md={3} sm={4} xs={5}>
          <div style={{ width: 70, height: 70, overflow: 'hidden', borderRadius: 6 }}>
            <img
              height={70}
              width={70}
              src={item.variant.images[0]?.medium}
              alt=""
              className="cart-image"
            />
          </div>
        </View.Item>
        <View.Item as={Col} colspan={24} md={21} sm={20} xs={19}>
          <Grid fluid>
            <Row>
              <Col md={14} sm={24} xs={24} className='cart-detail'>
                <h6>{item.variant.name}</h6>
              </Col>
              <Col md={4} sm={13} xs={14} className='cart-detail'>
                <h6>
                  {`${parseFloat(item.price).toLocaleString(undefined, {style:"currency", currency: item.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                </h6>
              </Col>
              <Col md={6} sm={11} xs={10}>
                <ButtonGroup className='qty-control'>
                  <Button disabled={disabled || updating?.id === item.id} appearance="default" onClick={() => onUpdateQuantity(item, item.quantity - 1)}>-</Button>
                  <Button className='qty-control-count' loading={updating?.id === item.id} disabled appearance="subtle">{item.quantity}</Button>
                  <Button disabled={disabled || updating?.id === item.id} appearance="default" onClick={() => onUpdateQuantity(item, item.quantity + 1)}>+</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Grid>
        </View.Item>
      </View>
    </Panel>
  )
}