import api from './common/api'

export const checkout = async (params, cb = null) => {
  return api({auth: false, version: 'api/storefront'})
    .post(`/checkouts`, params)
    .then((response, error) => {
      if (cb) cb(response, error);
    })
    .catch((error) => {
      if (cb) cb(null, error);
    });
}

// export const createPaymentMethod = async (type, card) => {}

// export const attachPaymentMethod = async ({payment_intent_id, payment_method, client_key, return_url}) => {}

export const fetchPaymentMethods = async (cb = null) => {
  return api({auth: false, version: 'api/storefront'})
    .get(`/payment_methods`)
    .then((response, error) => {
      if (cb) cb(response, error);
    })
    .catch((error) => {
      if (cb) cb(null, error);
    });
}