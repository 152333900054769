import { useDispatch, useSelector } from 'react-redux';
import Product from '../../components/product.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchProduct } from '../../actions/products';
import '../../assets/modalproduct.css'
import { Modal } from 'rsuite';
import TopNav from '../../components/top_nav.jsx';

export default function ProductPage(){
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const product = useSelector(state => state.products.byId[id])

  useEffect(() => {
    if (!product) {
      dispatch(fetchProduct(id, (response, error) => {
        if (error) {
          navigate('/404')
        }
      }))
    }
  }, [])
  
  return(
    <Modal className='modal-dialog-product-view' size="full" open={true}>
      <TopNav onClose={() => handleClose()}/>
      <Modal.Body className="product-view-container">
        <Product product={product} />
      </Modal.Body>
    </Modal>
  )
}