import {
  Form, Panel, Col, FlexboxGrid as View,Button,
  toaster, Message
} from 'rsuite';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { authenticate, fetchCurrentUser } from '../../actions/authenticate';

export default function Login({setLoggedIn}){
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    email: '',
    password: ''
  })
  const [formErrors, setFormErrors] = useState({})

  const onAuthenticate = () => {
    let errors = {}
    if (!formValue.email) {
      errors['email'] = 'Email is required'
    }
    if (!formValue.password) {
      errors['password'] = 'Password is required'
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
    setLoading(true)
    // Add the following line
    // console.log(formValue)
    authenticate(formValue, (response, error) => {
      if (response) {
        dispatch(fetchCurrentUser())
        setLoggedIn(true)
      }
      setLoading(false)
      if (error) {
        if (error.response && error.response.data && error.response.data.message) {
          toaster.push(<Message showIcon type="error">
            {error.response.data.message}
          </Message>)
        }
      }
    })
  }

  return (
    <Panel className='p-x-10' bodyFill>
      <h5 style={{marginBottom: 30}}>Already have an account?</h5>
      <Form fluid>
        <View className='fluid'>
          <View.Item as={Col} colspan={24} md={24} sm={24} xs={24} style={{ marginBottom: 20 }}>
            <Form.Group className='m-b-10' controlId="login-email">
              <Form.ControlLabel>
                Email
                <Form.HelpText tooltip={!isMobile}>Email is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['email']}
                name="email" type="email"
                value={formValue.email}
                onChange={(email) => setFormValue({...formValue, email})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, email: null})
                  }
                }}
              />
            </Form.Group>
            <Form.Group className='m-b-10' controlId="password">
              <Form.ControlLabel>
                Password
                <Form.HelpText tooltip={!isMobile}>Password is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                value={formValue.password}
                disabled={loading}
                errorMessage={formErrors['password']}
                name="password"
                type="password"
                onChange={(password) => setFormValue({...formValue, password})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, password: null})
                  }
                }}
              />
            </Form.Group>
          </View.Item>
        </View>
        <div style={{textAlign: 'right'}}>
          <Button loading={loading} appearance="primary" onClick={() => onAuthenticate()} >Sign In</Button>
        </div>
      </Form>
    </Panel>
  )
}