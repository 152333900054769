import api from './common/api'
import { setValues, setValue, setMeta } from '../slices/products'

export const fetchProducts = (params, cb = null) => async dispatch => {
  const query = new URLSearchParams(params).toString();
  return api({auth: false})
  .get(`/products?${query}`).then(response => {
    const products = {}

    response?.data?.products?.data?.forEach(item => {
      products[item.id] = item.attributes
    })
    dispatch(setValues(products))
    dispatch(setMeta(response?.data?.meta))
  }).catch(error => {
    console.error(error);
  }).finally(() => {
    console.log("done")
    cb()
  });
}

export const fetchProduct =
  (id, params, cb = null) =>
  async (dispatch) => {
    const query = new URLSearchParams(params).toString();
    return api({auth: false})
      .get(`/products/${id}?${query}`)
      .then((response, error) => {
        const product = {};
        product[response.data.product.data.attributes.id] =
          response.data.product.data.attributes;
        dispatch(setValue(product));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };