import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// import App from '~/components/App.jsx'
import { CustomProvider, Loader } from 'rsuite'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { store } from '~/store.jsx'

import 'rsuite/dist/rsuite.min.css';
import '../assets/index.scss'
import Layout from '../components/Layout'
import CleanLayout from '../components/CleanLayout'
import ErrorPage from '../components/Error'
import Home from '../screens/home'
import Product from '../screens/product'
import Cart from '../screens/cart'
import Authenticate from '../screens/authenticate'
import Payment from '../screens/payment'
import Purchased from '../screens/payment/success'
import Failed from '../screens/payment/failed'

import { SettingsProvider } from '../context/settings.context';
import { StoreProvider } from '../context/store.context';
import { CartProvider } from '../context/cart.context';
import Restaurants from '../screens/restaurants';
import Restaurant from '../screens/restaurants/show';
import RestaurantSearch from '../screens/restaurants/search';
import FlowerShops from '../screens/flowers';
import FlowerShop from '../screens/flowers/show';
import FlowerShopSearch from '../screens/flowers/search';
import Events from '../screens/events';


const root = createRoot(document.getElementById('react-spa-root'))
const SuspenseLoader = () => (
  <Loader center content="Loading..." />
)

const router = createBrowserRouter([
  { errorElement: <ErrorPage />, path: "/", element: <Layout><Home /></Layout>},
  { errorElement: <ErrorPage />, path: "/products/:id", element: <Layout><Product /></Layout>},
  { errorElement: <ErrorPage />, path: "/restaurants", element: <Layout><Restaurants /></Layout>},
  { errorElement: <ErrorPage />, path: "/restaurants/:id", element: <Layout><Restaurant /></Layout>},
  { errorElement: <ErrorPage />, path: "/restaurants/search", element: <Layout><RestaurantSearch /></Layout>},
  { errorElement: <ErrorPage />, path: "/flowers", element: <Layout><FlowerShops /></Layout>},
  { errorElement: <ErrorPage />, path: "/flowers/:id", element: <Layout><FlowerShop /></Layout>},
  { errorElement: <ErrorPage />, path: "/flowers/search", element: <Layout><FlowerShopSearch /></Layout>},
  { errorElement: <ErrorPage />, path: "/events", element: <CleanLayout><Events /></CleanLayout>},
  { errorElement: <ErrorPage />, path: "/events/:id", element: <CleanLayout><Events /></CleanLayout>},
  { errorElement: <ErrorPage />, path: "/carts", element: <Layout><Cart /></Layout>},
  { errorElement: <ErrorPage />, path: "/authenticate/:cart_id", element: <Layout><Authenticate /></Layout>},
  { errorElement: <ErrorPage />, path: "/payments/:id/success", element: <Layout><Purchased /></Layout>},
  { errorElement: <ErrorPage />, path: "/payments/:id", element: <Layout><Payment /></Layout>},
  { errorElement: <ErrorPage />, path: "/failed/:cart_id", element: <Layout><Failed /></Layout>},
])

const Root = () => {
  return (
    <Provider store={store}>
      <CustomProvider theme={import.meta.env.VITE_APP_THEME}>
        <Suspense fallback={<SuspenseLoader/>} >
          <SettingsProvider>
            <StoreProvider>
              <CartProvider>
                <RouterProvider router={router} />
              </CartProvider>
            </StoreProvider>
          </SettingsProvider>
        </Suspense>
      </CustomProvider>
    </Provider>
  )
}
root.render(React.createElement(Root))
