import {
  Container, FlexboxGrid as View, Col, Header,
  Footer, Navbar, Nav, Badge, Grid, Row, Button, Dropdown
} from 'rsuite';

import UnisolLogo from '../assets/unisol.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../assets/index.scss'
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/cart.context';
import { useStore } from '../context/store.context';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

export default function Layout({ children }) {
  const navigate = useNavigate()
  const { store } = useStore()
  const { setStoreId } = useCart()

  useEffect(() => {
    if (store) {
      setStoreId(store.id)
    }
  }, [store])

  const isDomainContains = (term) => {
    if (typeof window !== 'undefined') {
      const domainName = window.location.hostname;
      return domainName.toLowerCase().includes(term);
    } else {
      return false;
    }
  }

  const Logo = (props) => {
    if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace') {
      return <img {...props} src={`/${[import.meta.env.VITE_APP_SLUG, props.suffix].filter(Boolean).join("-")}.${props.type ? props.type : 'png'}`} alt={import.meta.env.VITE_APP_NAME}/>
    } else if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
      if (isDomainContains('unisol')) {
        return <img src="/unisol.png" alt="Unisol"/>
      } else {
        return <img width={30} height={30}  src={store?.logo} alt="logo" style={{ height: 30 }} />
      }
    }
  }

  return(
    <div className="show-container">
      <Container>
        <Header>
          {isMobile ? 
            <Navbar className='navbar-mobile' appearance='inverse'>
              <Navbar.Brand
                style={{ color: '#fff' }}
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/')
                }}
              >
                <Logo type="svg" suffix="events"/>
              </Navbar.Brand>
              <Nav pullRight>
                <Dropdown icon={<FontAwesomeIcon icon={faBars} />} noCaret placement='bottomEnd'>
                  <Dropdown.Item to='/merchants'>Food</Dropdown.Item>
                  <Dropdown.Separator style={{width: 300}}/>
                  <Dropdown.Item to='/events'>Event test</Dropdown.Item>
                  <Dropdown.Separator />
                  <Dropdown.Item>
                    <Button className='fw-600' appearance="primary" size="sm" to={import.meta.env.VITE_APP_URL}>Become a Partner</Button>                  
                  </Dropdown.Item>
                  <Dropdown.Separator />
                </Dropdown>
              </Nav>
            </Navbar>

          : <Navbar className='navbar-desktop' appearance='inverse'>
              <Navbar.Brand
                style={{ color: '#fff' }}
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/')
                }}
                >
                <Logo type="svg" suffix="events"/>
              </Navbar.Brand>
              <Nav pullRight>
                <Nav.Item>
                  <Button className='fw-600' appearance="primary" size="sm" to={import.meta.env.VITE_APP_URL}>Host an Event</Button>
                </Nav.Item>
              </Nav>
          </Navbar>}
        </Header>
        <Container className="content-clean-container">
          <Container className="content-clean-wrapper">
            {children}
            <Footer>
              <View justify='center'>
                <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} style={{ maxWidth: 1120 }}>
                  <Grid fluid>
                    <Row>
                      <Col lg={10} md={10} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        <Logo style={{ height: 30 }}/>
                        {!!store?.logo && <img width={30} height={30} src={store?.logo} alt="logo" style={{ height: 30 }} />}
                        <p style={{marginTop: 20}}>
                          <small style={{ display: "block" }}>
                            &copy; {new Date().getFullYear()} {import.meta.env.VITE_APP_NAME}. All Rights Reserved
                          </small>
                        </p>
                      </Col>
                      <Col lg={4} md={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        {/* <span className='footer-link-title'>Helpfull Links</span> */}
                        <ul className='footer-navlinks'>
                          <li><a target="_blank" href="https://tablenos.notion.site/Tablenos-Terms-of-Use-851beea8d62a4767869d8fdbec01523a">Terms</a></li>
                          <li><a target="_blank" href="https://tablenos.notion.site/Tablenos-Privacy-Policy-ef60807510334bb4a574b01fa701ae7e">Privacy Policy</a></li>
                        </ul>
                      </Col>
                      {/* <Col lg={4} ms={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        <span className='footer-link-title'>Get to Know Us</span>
                      </Col>
                      <Col lg={4} ms={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        <span className='footer-link-title'>Doing Business</span>
                      </Col>
                      <Col lg={2} md={2} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        social
                      </Col> */}
                    </Row>
                  </Grid>
                </View.Item>
              </View>
            </Footer>
          </Container>
          
        </Container>
      </Container>
    </div>
  )
}