import { Panel } from 'rsuite';
import { useSettings } from '../context/settings.context';

export default function ProductCard({product, onClick}){
  const { settings } = useSettings()
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  return(
    <Panel bodyFill style={{marginBottom: 30}}>
      <div className='product-img-wrapper' >
        {!!product?.primary_variant?.images && <img
          width={300}
          height={300}
          src={product?.primary_variant?.images[0]?.styles?.large}
          alt="product"
          className="product-image"
          onClick={(e) => {
            e.preventDefault()
            onClick()
          }}
        />}
      </div>
      <h6 className='product-title'>
        <a href={`/products/${product.id}`} onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        style={{ color: '#fff', fontWeight: 600 }}
        >{product.name}</a>
      </h6>
      {/* <div style={{ marginBottom: 10 }}>
        <small>
          XXS, XS, S, M, XL, 2XL, 4XL, 5XL
        </small>
      </div> */}
      <div className='smProdPrice'>
        <h5>{`${parseFloat(product.price).toLocaleString(undefined, {style:"currency", currency: product.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</h5>
      </div>
    </Panel>
  )
}