import { configureStore } from '@reduxjs/toolkit'
import carts from "./slices/carts";
import categories from "./slices/categories";
import events from "./slices/events";
import products from "./slices/products";
import stocks from "./slices/stocks";
import stores from "./slices/stores";
import prototypes from "./slices/prototypes";
import tables from "./slices/tables";
import taxonomies from "./slices/taxonomies";
import orders from "./slices/orders";
import user from "./slices/user";

export const store = configureStore({
  reducer: {
    carts,
    categories,
    events,
    products,
    stocks,
    stores,
    prototypes,
    tables,
    taxonomies,
    orders,
    user,
  },
})
