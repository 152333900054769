import { Modal } from 'rsuite';
import Product from './product.jsx';
import TopNav from './top_nav.jsx';

export default function ProductModal({open, handleClose, product, size = "full"}){
  if (!product) return null
  return(
    <Modal className='modal-dialog-product-view' size={size} open={open} onClose={handleClose}>
      <TopNav closable={true} onClose={() => handleClose()}/>
      <Modal.Body className="product-view-container">
        <Product product={product} />
      </Modal.Body>
    </Modal>
  )
}