export function formatOpeningHours(openingHours) {
  // Helper function to format time in the client's timezone
  function formatTime(isoString, timezone) {
      const date = new Date(isoString);
      return date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: timezone,
          hour12: true
      });
  }

  // Helper function to shorten day names
  function shortenDay(day) {
      return day.substring(0, 3);
  }

  // Get the client's timezone
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Days of the week in order for comparison
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // Format each entry
  const formattedHours = openingHours.map(entry => ({
      day: shortenDay(entry.day),
      open: formatTime(entry.open, clientTimezone),
      close: formatTime(entry.close, clientTimezone)
  }));

  // Group days with the same opening and closing times
  const groupedHours = {};
  formattedHours.forEach(({ day, open, close }) => {
      const timeRange = `${open}-${close}`;
      if (!groupedHours[timeRange]) {
          groupedHours[timeRange] = [];
      }
      groupedHours[timeRange].push(day);
  });

  // Format the grouped days into a string
  return Object.entries(groupedHours).map(([timeRange, days]) => {
      // Sort the days according to the order in daysOfWeek
      days.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));

      // Find ranges in the sorted days
      let ranges = [];
      let start = days[0];
      for (let i = 1; i < days.length; i++) {
          if (daysOfWeek.indexOf(days[i]) !== daysOfWeek.indexOf(days[i - 1]) + 1) {
              ranges.push(start === days[i - 1] ? start : `${start}-${days[i - 1]}`);
              start = days[i];
          }
      }
      ranges.push(start === days[days.length - 1] ? start : `${start}-${days[days.length - 1]}`);

      return `${ranges.join(', ')} (${timeRange})`;
  }).join(', ');
}


export function isStoreOpen(openingHours) {
  // Helper function to convert ISO time to minutes from midnight
  function timeToMinutes(isoTime) {
      const date = new Date(isoTime);
      return date.getHours() * 60 + date.getMinutes();
  }

  // Get current date and time
  const now = new Date();
  const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' });
  const currentTimeMinutes = now.getHours() * 60 + now.getMinutes();

  // Find today's opening hours
  const todayOpening = openingHours.find(entry => entry.day === currentDay);

  if (!todayOpening) {
      return false; // If no matching day found, store is closed
  }

  // Convert opening and closing times to minutes from midnight
  const openTimeMinutes = timeToMinutes(todayOpening.open);
  const closeTimeMinutes = timeToMinutes(todayOpening.close);

  // Check if current time is within opening hours
  if (currentTimeMinutes >= openTimeMinutes && currentTimeMinutes <= closeTimeMinutes) {
      return true;
  } else {
      return false;
  }
}