import {
  Divider,
  Panel,
  Button,
  Avatar,
  Col,
  DatePicker,
  FlexboxGrid as View,
  InputPicker,
  IconButton,
  toaster,
  Message,
  Stack
} from 'rsuite';
import { Link, useNavigate } from "react-router-dom";
import CartItem from '../../components/cart_item.jsx';
import { updateCart, updateItem } from '../../actions/carts.jsx';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import TruckIcon from '@rsuite/icons/legacy/Truck';
import { searchPlaces } from '../../actions/places.jsx';
import { isBefore } from 'rsuite/esm/utils/dateUtils.js';
import { useSettings } from '../../context/settings.context.jsx';

export default function Cart({cart}){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [updatingQuantity, setUpdatingQuantity] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const [places, setPlaces] = useState([])
  const [placeId, setPlaceId] = useState(null)
  const [loadingPlaces, setLoadingPlaces] = useState(false)

  useEffect(() => {
    if (addressQuery.length > 3) {
      const timer = setTimeout(async () => {
        setLoadingPlaces(true)
        searchPlaces(settings.COUNTRIES, addressQuery, (results) => {
          if (results) {
            setPlaces(results.map(place => place.placePrediction))
          }
          setLoadingPlaces(false)
        })
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [addressQuery])

  const updateQuantity = (item, quantity) => {
    setUpdatingQuantity(item)
    dispatch(updateItem(cart.id, {
      variant_id: item.variant_id,
      quantity,
      store_id: cart.store.id
    }, () => {
      setUpdatingQuantity(false)
    }))
  }
  const onUpdate = (params) => {
    dispatch(updateCart(cart.id, params, (response, error) => {
      if (error?.response) {
        toaster.push(<Message showIcon type="error">
          {error.response.data.message}
        </Message>)
      }
    }))
  }

  const onValidateCheckout = () => {

    if (cart.fulfillment_type === "delivery" && !cart.shipping_address?.place_id) {
      toaster.push(<Message showIcon type="error">
        Please select a delivery address
      </Message>)
      return
    }
    if (cart.fulfillment_type === "delivery" && !cart.delivery_date) {
      toaster.push(<Message showIcon type="error">
        Please select a delivery date
      </Message>)
      return
    }
    if (cart.fulfillment_type === "delivery" && 
      isBefore(Date.parse(cart.delivery_date), new Date().setHours(0, 0, 0, 0))
    ) {
      toaster.push(<Message showIcon type="error">
        Choose a date for delivery in the future.
      </Message>)
      return false
    }
    if (cart.fulfillment_type === "pickup" && !cart.pickup_date) {
      toaster.push(<Message showIcon type="error">
        Please select a pick-up date
      </Message>)
      return
    }
    if (cart.fulfillment_type === "pickup" && 
      isBefore(Date.parse(cart.pickup_date), new Date().setHours(0, 0, 0, 0))
    ) {
      toaster.push(<Message showIcon type="error">
        Choose a date for pick-up in the future.
      </Message>)
      return false
    }

    navigate(`/authenticate/${cart.id}`)
  }

  if (!cart?.line_items?.slice()?.length) return null

  return (
    <Panel bordered bodyFill className='order-item m-b-20'>
      <div className="order-item-head">
        <View className='fluid' align='middle'>
          
          <View.Item as={Col} colspan={2} md={2} sm={4} xs={4}>
            <Avatar src={cart.store.logo} className='merchant-photo' />
          </View.Item>
          <View.Item as={Col} colspan={10} md={10} sm={20} xs={20}>
            <Link to={`/restaurants/${cart.store.slug}`}>
              <span className='merchant-store-link'>{cart.store.name}</span>
            </Link>
            <span className='merchant-store-location'>{cart.store.location}</span>
          </View.Item>

          <View.Item as={Col} colspan={12} md={12} sm={24} xs={24}>
            <div className="order-item-date-time">
              {cart.fulfillment_type === "pickup" && <Stack wrap justifyContent='end' spacing={10}>
                <Stack.Item >
                  <Button
                    icon={<TruckIcon color="grey"/>}
                    appearance='inverse'
                    onClick={() => {
                      onUpdate({fulfillment_type: cart.fulfillment_type ==="delivery" ? "pickup" : "delivery"})
                    }}
                  >
                    {cart.fulfillment_type === "delivery" ? "Change to Pick-up" : "Change to Delivery"}
                  </Button>
                </Stack.Item>
                <Stack.Item >
                  <DatePicker
                    shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                    style={{width: 220}}
                    placement='bottomEnd'
                    format="MM/dd/yyyy"
                    placeholder="Pick-up Date"
                    showMeridian
                    block
                    defaultValue={cart?.pickup_date ? new Date(cart?.pickup_date) : null}
                    onChange={(date) => onUpdate({pickup_date: date})}
                    onClean={() => onUpdate({pickup_date: null})}
                  />
                </Stack.Item>
              </Stack>}
              {cart.fulfillment_type === "delivery" && <Stack justifyContent='end' spacing={10}>
                <Stack.Item >
                  <Button
                    icon={<TruckIcon color={import.meta.env.VITE_APP_THEME === "light" ? "black" : "white"}/>}
                    appearance='inverse'
                    onClick={() => {
                      onUpdate({fulfillment_type: cart.fulfillment_type ==="delivery" ? "pickup" : "delivery"})
                    }}
                  >
                    {cart.fulfillment_type === "delivery" ? "Change to Pick-up" : "Change to Delivery"}
                  </Button>
                </Stack.Item>
                <Stack.Item >
                  <InputPicker
                    autoFocus
                    menuClassName='mp-auto-complete'
                    placement='bottomEnd'
                    name="address1"
                    data={places.map(place => ({label: place.text.text, value: place.placeId}))}
                    placeholder="Delivery Address"
                    block
                    renderMenu={menu => {
                      if (loadingPlaces) {
                        return (
                          <p style={{ padding: 10, textAlign: 'center' }}>
                            <SpinnerIcon spin /> Loading...
                          </p>
                        );
                      }
                      return menu;
                    }}
                    onSelect={(place_id, {label}) => {
                      setPlaceId(place_id)
                      onUpdate({place: { id: place_id, text: label }})
                    }}
                    valueKey="value"
                    labelKey="label"
                    onSearch={setAddressQuery}
                  />
                </Stack.Item>
                <Stack.Item>
                  <DatePicker
                    placement='bottomEnd'
                    shouldDisableDate={date => isBefore(date, new Date().setHours(0, 0, 0, 0)) }
                    format="MM/dd/yyyy"
                    placeholder="Delivery Date"
                    showMeridian
                    block
                    defaultValue={cart?.delivery_date ? new Date(cart?.delivery_date) : null}
                    onChange={(date) => onUpdate({delivery_date: date})}
                  />
                </Stack.Item>
                
              </Stack>}
            </div>
          </View.Item>

        </View>
      </div>
      <div className="order-item-list">
        <View>
          <View.Item as={Col} colspan={16} md={16} xs={24} sm={24}>
            {cart?.line_items?.slice()?.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)))?.map((item, index) => (
              <CartItem updating={updatingQuantity} key={index} item={item} onUpdateQuantity={updateQuantity}/>
            ))}
            {!cart?.line_items?.slice()?.length && <Panel className='m-b-10 empty-box' >
              <p>Your cart is empty</p>
            </Panel>}
          </View.Item>
          <View.Item as={Col} colspan={8} md={8} xs={24} sm={24}>
            <Panel className='cart-side-summary m-b-10' bordered>
              <View justify="space-between" className='m-b-5 p-b-5'>
                <View.Item>
                  <p>Items</p>
                </View.Item>
                <View.Item>
                  <p>x{cart?.line_items.map(item => item.quantity).reduce((result, item) => (result + item), 0)}</p>
                </View.Item>
              </View>
              <Divider className='m-t-5 m-b-5'/>
              <View justify="space-between" className='m-b-5 p-t-5'>
                <View.Item>
                  <p>Subtotal</p>
                </View.Item>
                <View.Item>
                  {!cart ? 0 : <p>{`${parseFloat(cart?.item_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</p>}
                </View.Item>
              </View>
              {cart?.fulfillment_type === "delivery" && <View justify="space-between" className='m-b-5'>
                <View.Item>
                  <p>Delivery</p>
                </View.Item>
                <View.Item>
                  {!cart ? 0 : <p>
                    {`${parseFloat(cart?.display_shipment_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                  </p>}
                </View.Item>
              </View>}
              {settings.SERVICE_FEE_EXCLUSIVE === "true" && <View justify="space-between" className='m-b-5 p-b-5'>
                <View.Item>
                  <p>Convenience Fee</p>
                </View.Item>
                <View.Item>
                  {!cart ? 0 : <p>
                    {`${parseFloat(cart?.display_service_fee).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                  </p>}
                </View.Item>
              </View>}
              <Divider className='m-t-5 m-b-5'/>
              <View justify="space-between" className='m-t-5 p-t-5'>
                <View.Item>
                  <h6>TOTAL AMOUNT</h6>
                </View.Item>
                <View.Item>
                {!cart ? 0 : <h6>{`${parseFloat(cart?.display_total).toLocaleString(undefined, {style:"currency", currency: cart?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</h6>}
                </View.Item>
                <View.Item as={Col} colspan={24} className='m-t-5 p-t-5 p-l-0 p-r-0'>
                  <Button className='fw-600' block appearance="primary" onClick={() => onValidateCheckout()}>Proceed to Checkout</Button>
                </View.Item>
              </View>
            </Panel>
          </View.Item>
        </View>
      </div>
    </Panel>
  );
}