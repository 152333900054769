import React, { createContext, useContext, useEffect, useState } from 'react'
import { fetchStore } from '../actions/stores';
import { useDispatch, useSelector } from "react-redux";

const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [slug, setStoreSlug] = useState(null)
  
  const [store, setStore] = useState(null)
  const stores = useSelector(state => state.stores)
  
  useEffect(() => {
    if (import.meta.env.VITE_PUBLIC_MODE === 'merchant')
      if (!store) {
        dispatch(fetchStore(slug))
      }
    else if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace')
      if (slug && !store) {
        dispatch(fetchStore(slug))
      }
    // eslint-disable-next-line
  }, [store, slug])

  useEffect(() => {
    if (!slug) {
      if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
        if (typeof window !== "undefined")
          setStoreSlug(window.location.hostname === "localhost" ? "demo-cafe" : window.location.hostname.split(".")[0])
        else
          setStoreSlug("demo-cafe")
      }
    }
  }, [])

  useEffect(() => {
    if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
      if (!store && stores.keys.length > 0) {
        setStore(stores.byId[stores.keys[0]])
      }
    } else if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace') {
      stores.keys.forEach(key => {
        if (stores.byId[key].slug === slug) {
          setStore(stores.byId[key])
        }
      })
    }
    // eslint-disable-next-line
  }, [stores, slug])

  const getStore = (slug = null, cb = null) => {
    dispatch(fetchStore(slug, {}, (response, error) => {
      if (response) {
        setStore(response.data.store.data.attributes)
      }
      if (cb) cb(response, error)
    }))
  }

  return (
    <StoreContext.Provider value={{ store, getStore, setStoreSlug }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);