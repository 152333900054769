import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {}
  },
  reducers: {
    setValue: (state, action) => {
      return({
        ...state,
        ...action.payload
      })
    },
  },
});

export const { setValue } = userSlice.actions;

export default userSlice.reducer;