import { FlexboxGrid as View, Content, Col, Form, InputGroup, Button, Panel, Stack, Grid, Row, Divider } from 'rsuite';
import '../../assets/search-panel.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

import partner from '../../assets/panel-img/panel-img-becomepartner.jpg';
import hostEvent from '../../assets/panel-img/panel-img-hostevent.jpg';
import { Link } from 'react-router-dom';

export default function RestaurantsSearch(){

  return(

    <Content>
      <div className="results-content-container">
        <div className="top-search-container">
          <View justify='center'>
            <View.Item as={Col} colspan={24} lg={14} md={22} sm={24} style={{ maxWidth: 1120 }}>
              <Form className='mp-searcharea-wrap' fluid>
                <View>
                  <View.Item as={Col} lg={20} md={20} sm={24} xs={24}>
                    <Form.Group controlId={'search-1'}>
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <FontAwesomeIcon icon={faLocationDot}/>
                          {/* <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 80, marginBlock: 30, color: '#81C784' }}/> */}
                        </InputGroup.Addon>
                        <Form.Control className='mp-seach-field' placeholder='Search for area, street name, landmark...' name="search-1" type="text" autoComplete="off" />
                      </InputGroup>
                    </Form.Group>
                  </View.Item>
                  <View.Item as={Col} lg={4} md={4} sm={24} xs={24} >
                    <Button appearance="primary">Let&apos;s Go</Button>
                  </View.Item>
                </View>
              </Form>
            </View.Item>
          </View>
        </div>

        <View justify='center' className="stack-media-horizontal m-b-30">
          <View.Item as={Col} colspan={24} lg={14} md={22} sm={24} style={{ maxWidth: 1120 }}>
            <h4 className='tile-heading m-b-20'>Merchant search results</h4>
            <View className='p-b-20'>
              <View.Item as={Col} colspan={24} className='tile-item m-b-10'>
                <Panel bordered bodyFill>
                  <Stack justifyContent='flex-start'>
                    <img src={hostEvent} alt="logo" width={100} height={100} style={{ width: 100, height: 100}} />
                    <div className='panel-tile-desc'>
                      <h6>Krush Burger</h6>
                      <small>Burgers, Italian, American</small>
                      <p><small>Within 44 km • Delivery 30min</small></p>
                    </div>
                  </Stack>
                </Panel>
              </View.Item>
              <View.Item as={Col} colspan={24} className='tile-item m-b-10'>
                <Panel bordered bodyFill>
                  <Stack justifyContent='flex-start'>
                    <img src={hostEvent} alt="logo" width={100} height={100} style={{ width: 100, height: 100}} />
                    <div className='panel-tile-desc'>
                      <h6>Krush Burger</h6>
                      <small>Burgers, Italian, American</small>
                      <p><small>Within 44 km • Delivery 30min</small></p>
                    </div>
                  </Stack>
                </Panel>
              </View.Item>
              <View.Item as={Col} colspan={24} className='tile-item m-b-10'>
                <Panel bordered bodyFill>
                  <Stack justifyContent='flex-start'>
                    <img src={hostEvent} alt="logo" width={100} height={100} style={{ width: 100, height: 100}} />
                    <div className='panel-tile-desc'>
                      <h6>Krush Burger</h6>
                      <small>Burgers, Italian, American</small>
                      <p><small>Within 44 km • Delivery 30min</small></p>
                    </div>
                  </Stack>
                </Panel>
              </View.Item>
            </View>
            <Divider/>
          </View.Item>
        </View>

        {import.meta.env.VITE_APP_SLUG === "barkadamarket" && <View justify='center' style={{ paddingBottom: 50 }}>
          <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24}>
            <div className='panel-tile-heading'>
              <h5>Join Us</h5>
              <p>Be a part of the Barkada Market story</p>
            </div>
            <div className='panel-tile-container'>
              <Grid fluid>
                <Row gutter={20}>
                  <Col lg={12} md={12} sm={24} style={{ marginBottom: 20 }}>
                    <Link to={import.meta.env.VITE_APP_URL}>
                      <Panel bordered bodyFill>
                        <Stack justifyContent='flex-start'>
                          <img src={partner} alt="logo" width={150} height={150} style={{ width: 150, height: 150}} />
                          <div className='panel-tile-desc'>
                            <h6>Become our partner</h6>
                            <small>Reach more customer and achieve growth with Barkada Market</small>
                          </div>
                        </Stack>
                      </Panel>
                    </Link>
                  </Col>
                  <Col lg={12} md={12} sm={24} style={{ marginBottom: 20 }}>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <img src={hostEvent} alt="logo" width={150} height={150} style={{ width: 150, height: 150}} />
                        <div className='panel-tile-desc'>
                          <h6>Host your events</h6>
                          <small>Coming Soon</small>
                        </div>
                      </Stack>
                    </Panel>
                  </Col>
                </Row>
              </Grid>
            </div>
          </View.Item>
        </View>}
      </div>
    </Content>
  );
}