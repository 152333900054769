import { Divider, ButtonGroup, Button, Panel, Rate, Content, Col, FlexboxGrid as View } from 'rsuite';
import { useEffect, useState } from 'react';
import { useStore } from '../../context/store.context';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Image from '../../assets/paid.svg';
import { fetchOrder, rateOrder } from '../../actions/orders';
import { useNavigate } from "react-router-dom";
import { useCart } from '../../context/cart.context';
import { useSettings } from '../../context/settings.context';

export default function Success(){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  
  const navigate = useNavigate()
  const { id: cart_id } = useParams()
  const dispatch = useDispatch()
  const { store } = useStore()
  const { removeCart } = useCart()
  const [ rating, setRating ] = useState(0)
  const order = useSelector(state => state.orders.byId[cart_id])

  useEffect(() => {
    if (cart_id) {
      dispatch(fetchOrder(cart_id, {
        with_experience_rating: true
      }, (response, error) => {
        setRating(response?.data?.order?.data?.attributes?.experience_rating)
      }))
    }
  }, [cart_id])

  useEffect(() => {
    const init = async () => {
      if (cart_id && order?.store?.id) {
        await removeCart(order?.id)
      }
    }
    init()
  }, [order, cart_id])

  const onRateOrder = (rating) => {
    setRating(rating)
    dispatch(rateOrder(order.store.id, cart_id, { rating: { rating } }))
  }

  const rememberOrder = async () => {
    if (order?.store && import.meta.env.VITE_PUBLIC_MODE === "merchant") {
      const storeId = order.store.id
      const orders = JSON.parse(localStorage.getItem(`${storeId}-orders`))
      const updatedOrders = orders ? [...orders, order.id] : [order.id]
      localStorage.setItem(`${storeId}-orders`, JSON.stringify(updatedOrders))
    }
  }

  const goTo = async (path) => {
    await removeCart(order?.id)
    navigate(path)
  }


  if (!order) return null
  return (
    <Content>
      <View justify="center">
        <View.Item as={Col} colspan={8} md={8} sm={8} xs={24}>
          <Panel style={{marginTop: 20}} className='align-center'>
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <h3>Thank You!</h3>
              <p>Your order was placed successfully</p>
              <img src={Image} alt="Success" style={{maxWidth: 300}}/>
            </div>
          </Panel>
          <Panel>
            <View justify='space-between'>
              <View.Item colspan={12}>
                {rating ? 'Thank you for these stars' : "How's your ordering experience?"}
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                <Rate
                  size="xs" 
                  readOnly={!!rating}
                  value={rating ? rating : 0}
                  onChange={onRateOrder}
                />
              </View.Item>
            </View>
          </Panel>
          <Panel style={import.meta.env.VITE_APP_THEME === "dark" ? styles.dark : styles.light}>
            <View justify='space-between' style={{marginBottom: 20}}>
              <View.Item colspan={12}>
                Order Details
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                #{order?.order_number}
              </View.Item>
            </View>
            {order?.line_items.map((item, index) => (
              <View justify='space-between' key={index}>
                <View.Item colspan={12}>
                  x{item.quantity} {item.variant.name}
                </View.Item>
                <View.Item colspan={12} style={{textAlign: "right"}}>
                  {`${parseFloat(item.price).toLocaleString(undefined, {style:"currency", currency: order?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                </View.Item>
              </View>
            ))}
            <Divider/>
            <View justify='space-between'>
              <View.Item colspan={12}>
                Total Order Price
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                {`${parseFloat(order?.total).toLocaleString(undefined, {style:"currency", currency: order?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
              </View.Item>
            </View>
            {order.fulfillment_type === "delivery" && <View justify='space-between'>
              <View.Item colspan={12}>
                <small>Delivery Fee</small>
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                <small>
                  {`${parseFloat(order?.display_shipment_total).toLocaleString(undefined, {style:"currency", currency: order?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                </small>
              </View.Item>
            </View>}
            {settings.SERVICE_FEE_EXCLUSIVE === "true" && <View justify='space-between'>
              <View.Item colspan={12}>
                <small>Convenience Fee</small>
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                <small>
                  {`${parseFloat(order?.display_service_fee).toLocaleString(undefined, {style:"currency", currency: order?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
                </small>
              </View.Item>
            </View>}
            <Divider/>
            <View justify='space-between'>
              <View.Item colspan={12}>
                TOTAL
              </View.Item>
              <View.Item colspan={12} style={{textAlign: "right"}}>
                {`${parseFloat(order?.display_total).toLocaleString(undefined, {style:"currency", currency: order?.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}
              </View.Item>
            </View>
          </Panel>
          <div style={{textAlign: 'center'}}>
            <div style={{marginBottom: 20}}>
              <small style={{marginBottom: 20}}>
                Your official receipt will be sent along with your order.
              </small>
            </div>
            <ButtonGroup justified>
              <Button appearance="default" onClick={() => goTo('/')}>Order More</Button>
            </ButtonGroup>
          </div>
        </View.Item>
      </View>
    </Content>
  );
}
const styles = {
  light: {
    background: 'white',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    marginBottom: 10
  },
  dark: {
    background: "#2b2b2b",
    minWidth: 200,
    width: '100%',
    padding: 20,
    marginBottom: 10
  }
}