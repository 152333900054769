import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Stack, Form, Panel, Col, FlexboxGrid as View, Button, toaster, Message
} from 'rsuite';
import { fetchCurrentUser, registration } from '../../actions/authenticate';
import { useDispatch } from 'react-redux';

export default function Signup({setLoggedIn}){
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState({
    username: '',
    email: '',
    password: '',
    phone: '',
    first_name: '',
    last_name: ''
  })
  const [formErrors, setFormErrors] = useState({})

  const validateForm = () => {
    const errors = {}
    if (!formValue.username) {
      errors['username'] = 'Username is required'
    }
    if (!formValue.first_name) {
      errors['first_name'] = 'First Name is required'
    }
    if (!formValue.last_name) {
      errors['last_name'] = 'Last Name is required'
    }
    if (!formValue.email) {
      errors['email'] = 'Email is required'
    }
    if (!formValue.phone) {
      errors['phone'] = 'Phone is required'
    }
    if (!formValue.password) {
      errors['password'] = 'Password is required'
    }
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return false
    }

    return true
  }

  const onSignUp = () => {
    if (validateForm()) {
      setLoading(true)
      // Add the following line
      // console.log(formValue)
      registration(formValue, (response, error) => {
        setLoading(false)
        if (error?.response) {
          console.log(error.response.data)
          // console.log(Object.keys(error.response.data.errors))
          if (Object.keys(error.response.data).length > 0) {
            Object.keys(error.response.data).map((key) => {
              toaster.push(<Message showIcon type="error">
                {key.toUpperCase()} {error.response.data[key]}
              </Message>) 
            })
          }
        }
        if (response) {
          dispatch(fetchCurrentUser())
          setLoggedIn(true)
        }
      })
    }
  }

  return (
    <Panel className='p-x-20' bodyFill style={{borderRight: '1px solid var(--rs-border-primary)', borderRadius: 0, paddingRight: 10}}>
      <h5 style={{marginBottom: 30}}>Your Contact Information</h5>
      <Form fluid>
        <Form.Group className='m-b-10' controlId="username">
          <Form.ControlLabel>
            Username
            <Form.HelpText tooltip={!isMobile}>Name is required</Form.HelpText>
          </Form.ControlLabel>
          <Form.Control
            disabled={loading}
            errorMessage={formErrors['username']}
            name="username"
            value={formValue.username}
            onChange={(username) => setFormValue({...formValue, username})}
            onBlur={(e) => {
              if (!!e.target.value) {
                setFormErrors({...formErrors, username: null})
              }
            }}
          />
        </Form.Group>
        <View className='fluid'>
          <View.Item as={Col} colspan={24} md={24} sm={24} xs={24} style={{ marginBottom: 20 }}>
            <Form.Group className='m-b-10' controlId="first_name">
              <Form.ControlLabel>
                First Name
                <Form.HelpText tooltip={!isMobile}>Name is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['first_name']}
                name="first_name"
                value={formValue.first_name}
                onChange={(first_name) => setFormValue({...formValue, first_name})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, first_name: null})
                  }
                }}
              />
              
            </Form.Group>
            <Form.Group className='m-b-10' controlId="last_name">
              <Form.ControlLabel>
                Last Name
                <Form.HelpText tooltip={!isMobile}>Name is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['last_name']}
                name="last_name"
                value={formValue.last_name}
                onChange={(last_name) => setFormValue({...formValue, last_name})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, name: null})
                  }
                }}
              />
              
            </Form.Group>
            <Form.Group className='m-b-10' controlId="signup-email">
              <Form.ControlLabel>
                Email
                <Form.HelpText tooltip={!isMobile}>Email is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['email']}
                name="email"
                value={formValue.email}
                type="email"
                onChange={(email) => setFormValue({...formValue, email})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, email: null})
                  }
                }}
              />
            </Form.Group>
            <Form.Group className='m-b-10' controlId="phone">
              <Form.ControlLabel>
                Phone
                <Form.HelpText tooltip={!isMobile}>Phone is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['phone']}
                name="phone"
                value={formValue.phone}
                type="phone"
                onChange={(phone) => setFormValue({...formValue, phone})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, phone: null})
                  }
                }}
              />
            </Form.Group>
            <Form.Group className='m-b-10' controlId="signup-password">
              <Form.ControlLabel>
                Password
                <Form.HelpText tooltip={!isMobile}>Password is required</Form.HelpText>
              </Form.ControlLabel>
              <Form.Control
                disabled={loading}
                errorMessage={formErrors['password']}
                name="password"
                value={formValue.password}
                type="password"
                onChange={(password) => setFormValue({...formValue, password})}
                onBlur={(e) => {
                  if (!!e.target.value) {
                    setFormErrors({...formErrors, password: null})
                  }
                }}
              />
            </Form.Group>
          </View.Item>
        </View>
        <div style={{textAlign: 'right'}}>
          <Button loading={loading} appearance="primary" onClick={() => onSignUp()} >Sign Up</Button>
        </div>
      </Form>
    </Panel>
  )
}