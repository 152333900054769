import { FlexboxGrid as View, Content, Col, Stack, Panel, Grid, Row } from 'rsuite';
import Cover from '../../../components/bd_cover.jsx';
import { Link } from 'react-router-dom';

import food from '../../../assets/panel-img/bd-panel-img/bd-panel-img-food.webp';
import events from '../../../assets/panel-img/bd-panel-img/bd-panel-img-event.webp';
import services from '../../../assets/panel-img/bd-panel-img/bd-panel-img-services.webp';
import shop from '../../../assets/panel-img/bd-panel-img/bd-panel-img-shop.webp';

// import partner from '../../../assets/panel-img/panel-img-becomepartner.jpg';
// import hostEvent from '../../../assets/panel-img/panel-img-hostevent.jpg';
import '../../../assets/bdhome.scss'

export default function BarkadaDeliveryHome(){
  return (
    <Content>
      {/* <div className='mp-bg'></div> */}
      <Cover alt="cover"/>
      <View justify='center' style={{ marginBottom: 80 }}>
        <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} className='max-1120'>
          <div className='panel-tile-heading'>
            <h5>Your everyday, right away</h5>
            <p>Order food and grocery delivery online from hundreds of restaurants and shops nearby.</p>
          </div>
          <div className='panel-tile-container'>
            <Grid fluid>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Link to='/restaurants'>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={food} alt="logo" width={120} height={130} />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h6>Food</h6>
                          <small>Find deals, free delivery, and more from our curated restaurant partners.</small>
                          <span className='link-placeholder'>Explore</span>
                        </div>
                      </Stack>
                    </Panel>
                  </Link>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Link to='/flowers'>
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={shop} alt="logo" width={120} height={130} />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h6>Flowers</h6>
                          <small>Boost your sales and streamline operations with our comprehensive shop management features.</small>
                          <span className='link-placeholder'>Explore</span>
                        </div>
                      </Stack>
                    </Panel>
                  </Link>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  <Panel bordered bodyFill>
                    <Stack justifyContent='flex-start'>
                      <Stack.Item className='img-placeholder'>
                        <img src={services} alt="logo" width={120} height={130} />
                      </Stack.Item>
                      <div className='panel-tile-desc'>
                        <h6>Services - Coming Soon</h6>
                        <small>Maximize service potential and enhance customer satisfaction with our management features.</small>
                      </div>
                    </Stack>
                  </Panel>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24} className='m-b-20'>
                  {/* <Link to='/events'> */}
                    <Panel bordered bodyFill>
                      <Stack justifyContent='flex-start'>
                        <Stack.Item className='img-placeholder'>
                          <img src={events} alt="logo" width={120} height={130} />
                        </Stack.Item>
                        <div className='panel-tile-desc'>
                          <h6>Events - Coming Soon</h6>
                          <small>Enhance your event and ensure its success with our robust management features.</small>
                        </div>
                      </Stack>
                    </Panel>
                  {/* </Link> */}
                </Col>
              </Row>
            </Grid>
          </div>
        </View.Item>
      </View>
    </Content>
  );
}