import MerchantHome from './merchant';
import MarketplaceHome from './marketplace';

export default function Home(){
  
  if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
    return <MerchantHome />
  } else if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace') {
    return <MarketplaceHome />
  }
  return null
}