import React, { createContext, useContext, useEffect, useState } from 'react'

const SettingsContext = createContext(null);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const rootElement = document.getElementById('react-spa-root');
    if (rootElement) {
      const data = rootElement.dataset.settings;
      if (data) {
        JSON.parse(data).forEach((setting) => {
          setSettings((prev) => ({ ...prev, [setting.name.toUpperCase()]: setting.value }));
        });
      }
    }
  }, []);
  
  return (
    <SettingsContext.Provider value={{ settings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);