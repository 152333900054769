import React, { useEffect, useState } from 'react';
import {
  Button, FlexboxGrid as View, Col, Container,
  Message, useToaster, Divider, Stack,
  Panel,
} from 'rsuite';
import { useDispatch } from "react-redux";
import { addToCart, createCart, updateItem } from '../actions/carts';
import { useCart } from '../context/cart.context';
import { useStore } from '../context/store.context';
import '../assets/modalproduct.css'
import '../assets/product.css'
import { isMobile } from 'react-device-detect';
import { useSettings } from '../context/settings.context';

export default function Product({product}){
  const { settings } = useSettings();
  const maximumFractionDigits = settings?.FRACTION_DIGITS
  const toaster = useToaster()
  const dispatch = useDispatch()
  const { store } = useStore()
  const { cartId, tableId, setCartId, cart, cartIds, merchantCarts } = useCart()
  const [variant_id, setVariantId] = useState(null);
  const [variant, setVariant] = useState();
  const [busy, setBusy] = useState(false)
  const [options, setOptions] = useState({})
  const [requiredOptions, setRequiredOptions] = useState({})
  const [optionErrors, setOptionErrors] = useState({})
  const [image, setImage] = useState(product?.primary_variant?.images ? product?.primary_variant?.images[0]?.styles?.portrait : '')

  const success = () => toaster.push(<Message showIcon type="success"><strong>Item added to your cart</strong></Message>)
  const failure = (error) => toaster.push(<Message showIcon type="error"><strong>{error.message}</strong></Message>)

  useEffect(() => {
    const obj = {}
    product?.options?.map(option => {
      obj[option.id] = null
    })
    setRequiredOptions(obj)
  }, [])

  const validateOptions = () => {
    const errors = {}
    product?.options?.map(option => {
      if (!requiredOptions[option.id]) {
        errors[option.id] = "Please select an option"
      }
    })
    setOptionErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onAddToCart = selected_variant_id => {
    if (!product && !product.primary_variant) return
    const variant_id = selected_variant_id ? selected_variant_id : product.primary_variant.id
    if (!validateOptions()) return
    const cartIds = merchantCarts(store.id).map(cart => cart.id)
    if (!cartIds.includes(cartId)) {
      setBusy(true)
      createAddToCart(variant_id)
    } else {
      setBusy(true)
      if (!!itemInCart(variant_id)?.length) {
        const quantity = (itemInCart(variant_id)[0]?.quantity || 0) + 1
        console.log("quantity", itemInCart(variant_id))
        dispatch(updateItem(cartId, { variant_id, store_id: store.id, quantity }, 
          (response, error) => {
            if (response) success()
            if (error) failure(error)
            setBusy(false)
          }
        ))
      } else
        dispatch(
          addToCart(
            cartId,
            { variant_id, store_id: store.id, quantity: 1 },
            (response, error) => {
              console.log(response)
              if (response) success()
              if (error) failure(error)
              setBusy(false)
            }
          )
        )
    }
  }

  const createAddToCart = (variant_id) => {
    setBusy(true)
    dispatch(createCart({ store_id: store.id, tableId  }, async (response, error) => {
      setBusy(false)
      if (response?.data?.cart?.data?.id) {
        const id = response.data.cart.data.attributes.id
        setCartId(id)
        const cartids = JSON.parse(localStorage.getItem("cartids")) || []
        cartids.push(id)
        localStorage.setItem("cartids", JSON.stringify([...new Set(cartids)]))
        localStorage.setItem([store.id, "cart", "id", tableId].filter(e=>e).join("-"), id)
        dispatch(
          addToCart(
            id,
            { variant_id, store_id: store.id, quantity: 1 },
            (response, error) => {
              if (response) success()
              if (error) failure(error)
            }
          )
        )
      }
    }))
  }

  const selectOption = (option, value) => {
    setRequiredOptions({ ...requiredOptions, [option.id]: value.id })
    setOptions({ ...options, [option.id]: value.id })
    const variant = product?.variants.find(variant => {
      return variant.option_values.find(option_value => {
        if (option_value.option_type_id === option.id && option_value.option_value_id === value.id) {
          return variant
        }
      }
    )})
    if (variant) {
      setVariantId(variant?.id)
      setVariant(variant)
      if (!!variant?.images?.length)
        setImage(variant?.images[0]?.styles?.portrait)
      console.log("images", variant)
    } else {
      setVariantId(null)
      setVariant(null)
    }
    if (optionErrors[option.id]) {
      const errors = { ...optionErrors }
      delete errors[option.id]
      setOptionErrors(errors)
    }
  }

  const itemInCart = (variant_id) => {
    return cart?.line_items?.filter(item => item.variant_id === variant_id)
  }

  const inCart = (variant_id) => {
    return itemInCart(variant_id)?.length > 0
  }

  if (!product) return null
  return(
    <Container className="product-view-wrapper" style={{ alignItems: 'stretch', height: '100%' }}>
      <View style={{ height: '100%' }}>
        <View.Item colspan={24} as={Col} md={12} sm={24} xs={24}>
          <div className='product-view-imgLg'>
            <img
              width={500}
              height={500}
              src={image}
              alt="product"
              className="product-image"
              style={{ 
                width: "100%",
                height: "auto",
               }}
            />
          </div>
        </View.Item>
        <View.Item colspan={24} as={Col} md={12} sm={24} xs={24}>
          <div className='product-view-info-wrapper'>
            <h4 style={{ fontWeight: 600 }}>{product.name}</h4>
            <Stack style={{ marginBottom: 10 }} divider={<Divider vertical />}>
              <small>
                {product.taxons?.map((taxon) => taxon.name).join(", ")}
              </small>
              {variant?.sku && <small>{variant?.sku}</small>}
            </Stack>
            
            <h3 style={{ fontWeight: '600', marginBottom: 10 }}>{`${parseFloat(product.price).toLocaleString(undefined, {style:"currency", currency: product.currency, minimumFractionDigits: 0, maximumFractionDigits})}`}</h3>
            {product?.options?.slice()?.sort((a, b) => {
              if (a.name === "size") return 1
              if (b.name === "size") return -1
              return 0
            })?.map((option, index) => {
              if (option.name === "color")
                return(
                  <div key={index} style={{ marginBottom: 20 }}>
                    <small style={{ display: 'block', marginBottom: 10, fontWeight: 700 }}>Color</small>
                    <Stack wrap spacing={6}>
                      {option.option_values.map((value, index) => {
                        if (options[option.id] === value.id)
                          return(
                            <Button key={index} appearance='ghost' active
                              onClick={() => selectOption(option, value)}
                            />
                          )
                        else
                          return(
                            <Button key={index} appearance='ghost'
                              onClick={() => selectOption(option, value)}
                            />
                          )
                      })}
                    </Stack>
                    {optionErrors[option.id] && <small style={{ color: '#D32F2F', marginTop: 10, marginBottom: 10, display: 'block', }}>{optionErrors[option.id]}</small>}
                  </div>
                )
              else
                return(
                  <div key={index} style={{ marginBottom: 20 }}>
                    <small style={{ display: 'block', marginBottom: 10, fontWeight: 700 }}>Size</small>
                    <Stack wrap spacing={6}>
                      {option.option_values.map((value, index) => {
                        if (options[option.id] === value.id)
                          return(
                            <Button
                              key={index}
                              appearance='ghost'
                              active
                              onClick={() => selectOption(option, value)}
                            >
                              {value.name}
                            </Button>
                          )
                        else
                          return(
                            <Button key={index} appearance='ghost'
                              onClick={() => selectOption(option, value)}
                            >
                              {value.name}
                            </Button>
                          )
                      })}
                    </Stack>
                    {optionErrors[option.id] && <small style={{ color: '#D32F2F', marginTop: 10, marginBottom: 10, display: 'block', }}>{optionErrors[option.id]}</small>}
                  </div>)
            })}
            {!isMobile && <Button
              loading={busy}
              appearance='primary'
              size='lg'
              onClick={() => onAddToCart(variant_id)}
            >
              Add to Cart
            </Button>}
            <Divider/>
            <p className='modal-product-description short'>{product.description}</p>
          </div>
        </View.Item>
      </View>
      {isMobile && <div style={{position: 'fixed', bottom: 0, left: 0, right: 0}}>
        <Panel style={{background: '#000'}}>
          <Button
            loading={busy}
            appearance='primary'
            size='lg'
            block
            onClick={() => onAddToCart(variant_id)}
          >
            Add to Cart
          </Button>
        </Panel>
      </div>}
    </Container>
  )
}