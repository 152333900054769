import {FlexboxGrid as View, Col} from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export default function BarkadaMarketCover({src, alt}){
  return (
    <div className='bd-hero'>
      <div className="hero-wrapper">
        <View justify='start' align='middle' style={{ height: '100%'}}>
          <View.Item as={Col} colspan={24} xl={13} lg={15} md={22} sm={24} >
            <h1 className='tagline'>Need anything for you?
            Explore Barkada Delivery</h1>
            <ul className='feat-list'>
              <li>
                <FontAwesomeIcon icon={faCircleCheck}/>
                <span>Food</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCircleCheck}/>
                <span>Catering</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCircleCheck}/>
                <span>Fashion</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCircleCheck}/>
                <span>Branding</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCircleCheck}/>
                <span>Promotion</span>
              </li>
            </ul>
          </View.Item>
        </View>
      </div>
    </div>
  );
}