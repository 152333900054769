import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchCart, fetchCarts } from '../actions/carts';

const CartContext = React.createContext(null);

export const CartProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [cartId, setCartId] = useState(null);
  const [cartIds, setCartIds] = useState([]);
  const [fetchingCart, setFetchingCart] = useState(false)
  const [storeId, setStoreId] = useState(null)
  const [cartItemsCount, setCartItemsCount] = useState(0)
  const [tableId, setTableId] = useState('')
  const carts = useSelector(state => state.carts)
  
  useEffect(() => {
    setTableId(localStorage.getItem('table_id'))
    const cartids = JSON.parse(localStorage.getItem("cartids")) || []
    setCartIds(cartids)
  }, [])

  useEffect(() => {
    if (cartIds.length > 0)
      dispatch(fetchCarts(cartIds))
  }, [cartIds])

  useEffect(() => {
    setCartItemsCount(getCartsItemsCount())
  }, [carts])

  useEffect(() => {
    const initialize = async (storeId, tableId) => {
      const id = [storeId, "cart", "id", tableId].filter(e=>e).join("-")
      if (typeof window !== "undefined")
        setCartId(localStorage.getItem(id))
    }
    if (storeId) initialize(storeId, tableId)
  }, [storeId, tableId])

  const removeCart = async (cartId, callback) => {
    if (storeId) {
      const id = [storeId, "cart", "id", tableId].filter(e=>e).join("-")
      localStorage.removeItem(id)
    }
    const localcarts = JSON.parse(localStorage.getItem(`cartids`))
    localStorage.setItem("cartids", JSON.stringify([...new Set(localcarts.filter(e => e !== cartId))]))
    setCartId(null)
    if (callback)
      setTimeout(() => {
        callback()
      }, 100)
  }

  const getCartsItemsCount = () => {
    return Object.values(carts.byId)
      .filter(cart => cart.payment_state === 'balance_due')
      .map(cart => {
        return cart.line_items.length
      })
      .reduce((a, b) => a + b, 0)
  }

  const merchantCarts = (storeId) => {
    return Object.values(carts.byId)
      .filter(cart => cart.store.id === storeId)
  }
  
  return (
    <CartContext.Provider value={{
      setStoreId,
      setTableId,
      tableId,
      cartId,
      cartIds,
      setCartId,
      removeCart,
      merchantCarts,
      fetchingCart,
      cart: !!carts?.byId[cartId]?.line_items?.slice()?.length ? carts?.byId[cartId] : null,
      cartItemsCount
    }}>

    {children}
    
    </CartContext.Provider>
  );
};

export const useCart = () => React.useContext(CartContext);