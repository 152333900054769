import {
  Container, FlexboxGrid as View, Col, Header,
  Footer, Navbar, Nav, Badge, Grid, Row, Button, Dropdown
} from 'rsuite';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../assets/index.scss'
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/cart.context';
import { useStore } from '../context/store.context';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

export default function Layout({ children }) {
  const navigate = useNavigate()
  const { store } = useStore()
  const { cartItemsCount, setStoreId } = useCart()
  const carts = useSelector(state => state.carts)

  useEffect(() => {
    if (store) {
      setStoreId(store.id)
    }
  }, [store])

  const isDomainContains = (term) => {
    if (typeof window !== 'undefined') {
      const domainName = window.location.hostname;
      return domainName.toLowerCase().includes(term);
    } else {
      return false;
    }
  }

  const Logo = (props) => {
    if (import.meta.env.VITE_PUBLIC_MODE === 'marketplace') {
      return <img {...props} src={`/${[import.meta.env.VITE_APP_SLUG, props.suffix].filter(Boolean).join("-")}.${props.type ? props.type : 'png'}`} alt={import.meta.env.VITE_APP_NAME}/>
    } else if (import.meta.env.VITE_PUBLIC_MODE === 'merchant') {
      if (isDomainContains('unisol')) {
        return <img src="/unisol.png" alt="Unisol"/>
      } else {
        return <img width={30} height={30}  src={store?.logo} alt="logo" style={{ height: 30 }} />
      }
    }
  }

  return(
    <div className="show-container">
      <Container>
        <Header>
          {isMobile ? 
            <Navbar className='navbar-mobile' appearance='inverse'>
              <Navbar.Brand
                style={{ color: '#fff' }}
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/')
                }}
              >
                <Logo />
              </Navbar.Brand>
              <Nav pullRight>
                <Dropdown icon={<FontAwesomeIcon icon={faBars} />} noCaret placement='bottomEnd'>
                  <Dropdown.Item onClick={() => navigate('/restaurants')}>
                    Food
                  </Dropdown.Item>
                  {import.meta.env.VITE_APP_EVENTS_ENABLED === "true" && <Dropdown.Item onClick={() => navigate('/events')}>
                    Events
                  </Dropdown.Item>}
                  <Dropdown.Item>
                    <Button block className='fw-600' appearance="primary" size="sm" to={import.meta.env.VITE_APP_URL}>Become a Partner</Button>
                  </Dropdown.Item>
                </Dropdown>
              </Nav>
            </Navbar>

            // Set Navbar theme color to "inverse for dark version"
          : <Navbar className='navbar-desktop' appearance='inverse'>
              <Navbar.Brand
                style={{ color: '#fff' }}
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/')
                }}
                >
                <Logo />
              </Navbar.Brand>
              <Nav pullRight>
                <Nav.Item onSelect={() => navigate('/restaurants')}>
                  Food
                </Nav.Item>
                {import.meta.env.VITE_APP_EVENTS_ENABLED === "true" && <Nav.Item onSelect={() => navigate('/events')}>
                  Events
                </Nav.Item>}
                <Nav.Item onSelect={() => navigate('/carts')}>
                  Become a Partner
                </Nav.Item>
                <Nav.Item onSelect={() => navigate('/carts')} icon={
                  <FontAwesomeIcon icon={faShoppingCart} />
                }>
                  {cartItemsCount > 0 && <Badge content={cartItemsCount} style={{position: 'absolute', top: 8, right: 8}}/>}
                </Nav.Item>
              </Nav>
          </Navbar>}
        </Header>
        <Container className="content-container">
          <Container className="content-wrapper">
            {children}
            <Footer className='footer-inverse'>
              <View justify='center'>
                <View.Item as={Col} colspan={24} xl={14} lg={17} md={22} sm={24} style={{ maxWidth: 1120 }}>
                  <Grid fluid>
                    <Row>
                      <Col lg={10} md={10} sm={24} xs={24} style={{ marginBottom: 20 }}>
                      <Logo style={{ height: 30 }}/>
                        <p style={{marginTop: 20}}>
                          <small style={{ display: "block" }}>
                            &copy; {new Date().getFullYear()} {import.meta.env.VITE_APP_NAME}. All Rights Reserved
                          </small>
                        </p>
                      </Col>
                      <Col lg={4} md={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        {/* <span className='footer-link-title'>Helpfull Links</span> */}
                        <ul className='footer-navlinks'>
                          <li><a target="_blank" href="https://tablenos.notion.site/Tablenos-Terms-of-Use-851beea8d62a4767869d8fdbec01523a">Terms</a></li>
                          <li><a target="_blank" href="https://tablenos.notion.site/Tablenos-Privacy-Policy-ef60807510334bb4a574b01fa701ae7e">Privacy Policy</a></li>
                        </ul>
                      </Col>
                      {/* <Col lg={4} ms={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        <span className='footer-link-title'>Get to Know Us</span>
                      </Col>
                      <Col lg={4} ms={4} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        <span className='footer-link-title'>Doing Business</span>
                      </Col>
                      <Col lg={2} md={2} sm={24} xs={24} style={{ marginBottom: 20 }}>
                        social
                      </Col> */}
                    </Row>
                  </Grid>
                </View.Item>
              </View>
            </Footer>
          </Container>
          
        </Container>
      </Container>
    </div>
  )
}