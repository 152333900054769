import api from './common/api'
import { setValue } from '../slices/user'

export const authenticate = async (params, cb = null) => {
  return api({ auth: false })
    .post(`/authenticate`, params)
    .then((response, error) => {
      if (response) {
        const { id, roles, name, email, username, access_token } = response.data
        const user = { name, email, username, access_token, id, roles }
        localStorage.setItem('user', JSON.stringify(user))
      }
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const registration = async (params, cb = null) => {
  return api({ auth: false })
    .post(`/registration`, params)
    .then((response, error) => {
      if (response) {
        const { id, roles, name, email, username, access_token } = response.data
        const user = { name, email, username, access_token, id, roles }
        localStorage.setItem('user', JSON.stringify(user))
      }
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}


export const fetchCurrentUser = (cb = null) => async dispatch => {
  return api({version: 'api'})
    .get(`/users/me`)
    .then((response, error) => {
      const user = {
        data: response.data.user.data.attributes
      }
      dispatch(setValue(user))
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const updateCurrentUser = (params, cb = null) => async dispatch => {
  return api()
    .put(`/users/me`, params)
    .then((response, error) => {
      const user = {
        data: response.data.user.data.attributes
      }
      dispatch(setValue(user))
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const sendPasswordlessLink = async (params, cb = null) => {
  return api({auth: false})
    .post(`/authenticate/passwordless`, params)
    .then((response, error) => {
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const verifyToken = async (params, cb = null) => {
  return api({auth: false})
    .post(`/authenticate/verify`, params)
    .then((response, error) => {
      if (response) {
        const { id, roles, name, email, username, access_token } = response.data
        const user = { name, email, username, access_token, id, roles }
        localStorage.setItem('user', JSON.stringify(user))
      }
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const changePassword = async (params, cb = null) => {
  return api()
    .put(`/users/me/password`, params)
    .then((response, error) => {
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const checkUser = async (params, cb = null) => {
  return api({auth: false, version: "api/storefront"})
    .post(`/users/check`, params)
    .then((response, error) => {
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}
