import api from './common/api'
import { setValues, setValue, setMeta } from '../slices/orders.jsx'

export const fetchOrders = (params, cb = null) => async dispatch => {
  const query = new URLSearchParams(params).toString();
  return api()
    .get(`/orders?${query}`, params)
    .then((response, error) => {
      const orders = {}
      response?.data?.orders?.data?.forEach(item => {
        orders[item.id] = item.attributes
      })
      dispatch(setValues(orders))
      dispatch(setMeta(response?.data?.meta))
      if (cb) cb(response, error)
    })
    .catch((error) => {
      if (cb) cb(null, error)
    })
}

export const fetchOrder =
  (id, params, cb = null) =>
  async (dispatch) => {
    const query = new URLSearchParams(params).toString();
    return api({auth: false})
      .get(`/orders/${id}?${query}`)
      .then((response, error) => {
        const order = {};
        order[response.data.order.data.attributes.id] =
          response.data.order.data.attributes;
        dispatch(setValue(order));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };

export const rateOrder =
  (store_id, id, params, cb = null) => 
  async (dispatch) => {
    return api()
      .post(`/orders/${id}/rate?store_id=${store_id}`, params)
      .then((response, error) => {
        const order = {};
        order[response.data.order.data.attributes.id] =
          response.data.order.data.attributes;
        dispatch(setValue(order));
        if (cb) cb(response, error);
      })
      .catch((error) => {
        if (cb) cb(null, error);
      });
  };