import {
  Container, Header, Content, Footer,
  FlexboxGrid, Col, Stack, Button, Divider, Panel
} from 'rsuite';
import '../../assets/events.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchEvents } from '../../actions/events';

export default function Events(){
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [featuredEvent, setFeaturedEvent] = useState(null)
  const events = useSelector(state => state.events)

  useEffect(() => {
    dispatch(fetchEvents())
  }, [])

  useEffect(() => {
    const sorted = Object.values(events.byId).sort(
      (a, b) => new Date(b.event_date) - new Date(a.event_date)
    )
    setData(sorted.slice(1))
    setFeaturedEvent(sorted[0])
  }, [events])

  const formatTime = (date) => {
    // Use this format
    return new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  return(
    <>
      <div className="events">
        {/* <div className="logo">
          <a href="/tickets" title="BM Events">
            <img src="/logo-bm-event.svg" width="130" height="45" alt="BM Events"/>
          </a>
        </div> */}
        <Container>
          <Header className="hero">
            <div
              className="hero-bg"
              style={{
                backgroundImage: "url('/bm-hero-event-bg.jpeg')"
              }}
            ></div>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item className="center" colspan={24}>
                <div className="tagline">
                  <h1 className="cover-text">Events Hub: Your Go-To for</h1>
                  <h1 className="cover-text-gradient">Memorable Experiences</h1>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Header>
          <Content style={{marginTop: "-30px"}}>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item as={Col} colspan={24} lg={15} md={22} sm={24} style={{maxWidth: 1120}}>
                <div style={{width: "100%"}}>
                  <h3 className="title-gradient" style={{marginBottom: "10px"}}>Upcoming Event</h3>
                </div>
                <FlexboxGrid className="mb-50" justify="space-between" align="middle">
                  <FlexboxGrid.Item as={Col} colspan={24} lg={10} md={10} sm={24}>
                    <Panel shaded bodyFill>
                      <img className="upcoming-event-image" src={featuredEvent?.poster?.large} height="772" width="1512" alt="" />
                    </Panel>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item as={Col} colspan={24} lg={14} md={14} sm={24} style={{textAlign: 'center'}}>
                    <Stack justifyContent="space-between" style={{marginBottom: "50px"}}>
                      <div className="upcoming-month">
                        <div>{featuredEvent ? new Date(featuredEvent.event_date).getMonth() : ''}</div>
                        <span>MONTH</span>
                      </div>
                      <div className="upcoming-day">
                        <div>{featuredEvent ? new Date(featuredEvent.event_date).getDate() : ''}</div>
                        <span>DAY</span>
                      </div>
                      <div className="upcoming-year">
                        <div>{featuredEvent ? new Date(featuredEvent.event_date).getFullYear() : ''}</div>
                        <span>YEAR</span>
                      </div>
                    </Stack>
                    <div style={{marginBottom: "20px"}}>
                      {featuredEvent && <div style={{fontSize: "20px", fontWeight: "600"}}>
                        {formatTime(featuredEvent.starts_at) + ' - ' + formatTime(featuredEvent.ends_at)}
                      </div>}
                      <div>{featuredEvent?.location}</div>
                    </div>
                    <Button
                      href={featuredEvent?.ticket_url}
                      size="lg"
                      appearance="primary"
                    >
                      Buy Ticket - Standard
                    </Button>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
                <div>
                  <h2 className="title">
                    {featuredEvent?.title}
                  </h2>
                  {/* <h6>Organized by Fiesta Pilipinas : www.fiestapilipinas.nz</h6> */}

                  <div className="desc">
                    <p>
                      {featuredEvent?.description}
                    </p>
                  </div>

                </div>
                
                <Divider style={{opacity: "0.1", marginTop: "50px", marginBottom: "50px"}} />

                <div className="event-incoming-cards">
                  <div style={{width: "100%"}}>
                    <h3 className="title-gradient" style={{marginBottom: "10px"}}>Incoming Event</h3>
                  </div>
                  <FlexboxGrid justify="start" style={{marginBottom: "100px"}}>
                    
                    {data.map((event, index) => (
                      <FlexboxGrid.Item key={index} as={Col} colspan={8} lg={8} md={12} sm={24} xs={24}>
                        <Panel shaded bordered bodyFill style={{ display: 'block'}}>
                          <div className="image">
                            <a href={`/events/${event.slug}`} title={event.title}>
                              <img src={event.poster?.large} height="240" width="180" alt="" />
                            </a>
                          </div>
                          <Panel header={<a className="event-title ellipsis1" href={`/events/${event.slug}`} title={event.title}>{event.title}</a>}>
                            <small className="ellipsis1">
                              {event.event_date}
                              <span style={{margin: '10px'}}>•</span>
                              {event.location}
                            </small>
                          </Panel>
                        </Panel>
                      </FlexboxGrid.Item>
                    ))}
                  </FlexboxGrid>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Content>
        </Container>
      </div>
    </>
  )
}